import React from 'react';

import { VisibilityOutlined } from '@mui/icons-material';
import { Stack } from '@mui/material';

import QuickColumnButton from '@ivy/components/molecules/QuickColumnButton';

import ColumnMenu from './ColumnMenu';
import { useFilterContext, INITIAL_COLUMNS } from './useJobPostingFilters';

// TODO Add profession filter back in

export default function FacilityBar({ ...props }) {
	const { rawColumns, applyColumns, setRawColumns, discardColumns } =
		useFilterContext();

	return (
		<Stack direction='row' spacing={2} alignItems='center' {...props}>
			<QuickColumnButton
				onApply={applyColumns}
				onDiscard={discardColumns}
				value={rawColumns}
				initialValue={INITIAL_COLUMNS}
				onChange={setRawColumns}
				label={
					<>
						<VisibilityOutlined
							fontSize='small'
							sx={{ position: 'relative', mr: 1 }}
						/>
						Additional columns
					</>
				}
				hideFooter
				renderInput={(value, onChange) => (
					<ColumnMenu value={value} onChange={onChange} />
				)}
				sx={{
					bgcolor: '#2682C5',
					color: 'white',
					'&:hover': {
						color: 'text.primary',
					},
				}}
			/>
			{/* <QuickFilterButton
				onApply={applyFilters}
				onDiscard={discardFilters}
				field='professions'
				value={rawFilters}
				initialValue={INITIAL_FILTERS['professions']}
				onChange={setRawFilters}
				label='Profession'
				hideFooter
				badgeContent={filtersCount.professions}
				renderInput={(value, onChange) => (
					<ProfessionFilter value={value} onChange={onChange} />
				)}
			/> */}
		</Stack>
	);
}
