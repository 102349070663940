import React from 'react';

import { Box, Button, Typography } from '@mui/material';

import Popup from '@ivy/components/molecules/Popup';

import ProfessionFilter from './ProfessionFilter';
import { useFilterContext } from './useJobPostingFilters';

// TODO Add profession filter back in

interface FiltersPopupProps {
	open: boolean;
	onClose: () => void;
}

const FiltersPopup = ({ open, onClose }: FiltersPopupProps) => {
	const { resetFilters, rawFilters, applyFilters, setRawFilters } =
		useFilterContext();

	const handleChangeFilter =
		(field: string) => (ev: React.SyntheticEvent, nv: string[]) => {
			setRawFilters({
				...rawFilters,
				[field]: nv,
			});
		};

	const handleClickApply = () => {
		applyFilters();
		onClose();
	};

	const handleClickReset = () => {
		resetFilters();
		onClose();
	};

	return (
		<Popup
			open={open}
			onClose={onClose}
			title='Filters'
			actions={
				<>
					<Button onClick={onClose} sx={{ mr: 'auto' }}>
						Cancel
					</Button>
					<Button variant='outlined' onClick={handleClickReset} sx={{ mr: 1 }}>
						Reset
					</Button>
					<Button variant='contained' onClick={handleClickApply}>
						Apply
					</Button>
				</>
			}
		>
			<Box mb={4}>
				<Typography mb={2} variant='h6' component='h2'>
					Profession
				</Typography>
				<ProfessionFilter
					value={rawFilters.professions}
					onChange={handleChangeFilter('professions')}
				/>
			</Box>
		</Popup>
	);
};

export default FiltersPopup;
