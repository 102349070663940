import React from 'react';

import { Box, Stack, Divider } from '@mui/material';

import BillingDisplay from '@ivy/components/organisms/BillingDisplay';
import CurrentPlan from '@ivy/components/organisms/CurrentPlan';
import { type FormStepProps } from '@ivy/lib/forms/formFormatHelpers';
import { CheckoutModes } from '@ivy/lib/services/billing';

import { type CheckoutFormValues, type ExtraParams } from '../CheckoutForm';

interface BillingStepValues {
	name: string;
	email: string;
	streetAddress1: string;
	streetAddress2: string;
	city: string;
	state: string;
	zipcode: string;
}

const initialValues = {
	name: '',
	email: '',
	streetAddress1: '',
	streetAddress2: '',
	city: '',
	state: '',
	zipcode: '',
};

const BillingStep = ({
	formik,
	extra,
}: FormStepProps<CheckoutFormValues, ExtraParams>) => {
	const { values } = formik;

	return (
		<Stack spacing={8}>
			{extra.currSubscription || extra.legacyJobPostingCount ? (
				<Box>
					<CurrentPlan
						slots={extra.currSubscription?.slots}
						interval={extra.currSubscription?.interval}
						subtotal={extra.currSubscription?.invoice?.total}
						status={extra.currSubscription?.status}
						legacyCount={
							!extra.currSubscription ? extra.legacyJobPostingCount : undefined
						}
						hideTotal={extra.mode === CheckoutModes.PAYMENT}
					/>
					<Divider sx={{ mt: 3 }} />
				</Box>
			) : undefined}
			<BillingDisplay
				editMode
				formik={formik}
				defaultValues={{
					name: values.name,
					email: values.email,
					streetAddress1: values.streetAddress1,
					streetAddress2: values.streetAddress2,
					city: values.city,
					state: values.state,
					zipcode: values.zipcode,
				}}
				sx={{
					width: { xs: 'auto', sm: '550px' },
				}}
			/>
		</Stack>
	);
};

export default BillingStep;
export { type BillingStepValues, initialValues };
