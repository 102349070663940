import React, { useState } from 'react';

import {
	Chip,
	Box,
	SvgIcon,
	Stack,
	type SxProps,
	type Theme,
	Typography,
	Button,
	type ButtonProps,
	Switch,
	type SliderProps,
	Slider,
} from '@mui/material';

import RouteLink from '@ivy/components/atoms/RouteLink';
import { BriefcaseIcon } from '@ivy/components/icons';
import FeatureLineItem, {
	type FeatureLineItemProps,
} from '@ivy/components/molecules/FeatureLineItem';
import { useRedirect } from '@ivy/components/providers/RedirectProvider';
import { RouteUri } from '@ivy/constants/routes';
import { type ForEmployers_FacilityCountQuery } from '@ivy/gql/types/graphql';
import { formatInteger } from '@ivy/lib/formatting/number';
import {
	resolveJobPostingPrice,
	resolveBasePrice,
	JobPostPriceInterval,
	calculateDiscountAmount,
	calculateDiscountedPrice,
	getJobPostValRep,
	LEON_CALENDAR_LINK,
	billingSliderMarks,
} from '@ivy/lib/services/billing';
import { combineSx } from '@ivy/lib/styling/sx';

interface PlanCardProps {
	title: string;
	features: FeatureLineItemProps[];
	badge?: string;
	chipTitle?: boolean;
	buttonProps?: ButtonProps;
	buttonText?: string;
	price?: string | number;
	priceSubText?: string | number;
	planCount?: string;
	planSubText?: string;
	sliderProps?: SliderProps;
	crossPriceSubText?: boolean;
	basePrice?: number;
	sx?: SxProps<Theme>;
}

const PlanCard = ({
	badge,
	title,
	chipTitle,
	features,
	buttonProps,
	buttonText,
	price,
	priceSubText,
	planCount,
	planSubText,
	sliderProps,
	crossPriceSubText,
	basePrice,
	sx,
}: PlanCardProps) => {
	return (
		<Box
			sx={combineSx(
				{
					borderRadius: '16px',
					overflow: 'hidden',
					boxShadow: '0px 16px 40px rgba(142, 149, 155, 0.3)',
					bgcolor: (theme) => theme.palette.background.paper,
				},
				sx,
			)}
		>
			{badge ? (
				<Box
					sx={{
						px: 3,
						py: 1,
						background:
							'linear-gradient(271deg, #1B8374 1.82%, #1EC86A 178.69%)',
					}}
				>
					<Typography
						variant='body1'
						fontWeight='bold'
						color='primary.contrastText'
					>
						{badge}
					</Typography>
				</Box>
			) : null}
			<Box
				sx={{
					display: 'flex',
					width: '100%',
					alignItems: 'center',
					p: {
						xs: 3,
						lg: 5,
					},
				}}
			>
				<Stack spacing={5}>
					<Box>
						{chipTitle ? (
							<Chip
								label={title}
								sx={{
									height: 'auto',
									fontSize: '16px',
									fontWeight: 'bold',
									p: 0.5,
									bgcolor: 'rgba(30, 200, 106, 0.20)',
									color: 'text.icon',
								}}
							/>
						) : (
							<Typography
								variant='body1'
								color='text.icon'
								fontWeight='bold'
								sx={{
									p: 0.5,
								}}
							>
								{title}
							</Typography>
						)}
					</Box>
					<Box>
						{price ? (
							<Typography
								variant='h3'
								sx={{ display: 'flex', alignItems: 'center' }}
							>
								${price}
								<Typography
									variant='body1'
									color='text.icon'
									sx={{
										ml: 1,
									}}
								>
									<Typography
										sx={{
											display: basePrice ? 'inline' : 'none',
											textDecoration: crossPriceSubText
												? 'line-through'
												: 'none',
											mr: 0.5,
										}}
									>
										${basePrice}{' '}
									</Typography>
									{priceSubText}
								</Typography>
							</Typography>
						) : null}
						{planCount ? (
							<Typography
								variant='body1'
								fontWeight='bold'
								sx={{
									display: 'flex',
									flexDirection: 'column',
									mt: 1,
								}}
							>
								{planCount}
								<Typography component='span' variant='body1' fontWeight='bold'>
									{planSubText}
								</Typography>
							</Typography>
						) : null}
						{sliderProps ? (
							<Stack
								display='flex'
								direction='row'
								spacing={2}
								sx={{ alignItems: 'center', justifyContent: 'center' }}
							>
								<BriefcaseIcon sx={{ fontSize: 'inherit', mb: '-2px' }} />
								<Slider {...sliderProps} sx={{ mt: 1, height: '16px' }} />
							</Stack>
						) : null}
					</Box>
					<Stack spacing={2}>
						{features.map((props, idx) => (
							<FeatureLineItem key={`${title}-feature-${idx}`} {...props} />
						))}
					</Stack>
					<Box>
						<Button {...buttonProps}>{buttonText || 'Get Started'}</Button>
					</Box>
				</Stack>
			</Box>
		</Box>
	);
};

interface JobPostPlansProps {
	jobPostData: ForEmployers_FacilityCountQuery['jobPostPrices'];
	sx?: SxProps<Theme>;
}

const JobPostPlans = ({ jobPostData, sx }: JobPostPlansProps) => {
	const redirect = useRedirect();
	const [yearly, setYearly] = useState(false);
	const [sliderValue, setSliderValue] = useState(2);

	const handleSliderChange = (val: number | number[]) => {
		if (!Array.isArray(val)) setSliderValue(val);
	};

	const interval = yearly
		? JobPostPriceInterval.YEARLY
		: JobPostPriceInterval.MONTHLY;
	const prices = resolveJobPostingPrice(jobPostData, interval);
	const basePrice = resolveBasePrice(jobPostData);

	const handleClick = (slideVal: number) => {
		if (!slideVal) {
			window.open(LEON_CALENDAR_LINK, '_blank');
		} else {
			redirect({
				pathname: RouteUri.CHECKOUT_FLOW,
				search: new URLSearchParams({
					slots: getJobPostValRep(slideVal).toString(),
					interval: yearly
						? JobPostPriceInterval.YEARLY
						: JobPostPriceInterval.MONTHLY,
				}).toString(),
			});
		}
	};

	return (
		<Box
			component='section'
			sx={combineSx(
				{
					py: 10,
					position: 'relative',
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					overflow: 'hidden',
				},
				sx,
			)}
		>
			<Box
				sx={{
					position: 'absolute',
					top: 0,
					bottom: '90%',
					left: 0,
					right: 0,
					display: 'flex',
					justifyContent: 'center',
					zIndex: 1,
				}}
			>
				<SvgIcon
					width='1950'
					height='220'
					viewBox='0 0 1950 220'
					fill='none'
					xmlns='http://www.w3.org/2000/svg'
					preserveAspectRatio='none'
					sx={(theme) => ({
						width: '1920px',
						height: '100%',
						[theme.breakpoints.up(1920)]: {
							width: '100%',
						},
					})}
				>
					<path
						d='M925.565 54.5547C1350.57 -117.445 1949.57 219.555 1949.57 219.555V0H0.0644531C1.92663 82.1904 0.0651131 129.055 0.0651131 129.055C0.0651131 129.055 500.565 226.555 925.565 54.5547Z'
						fill='#CDEFDC'
					/>
				</SvgIcon>
			</Box>
			<Box
				sx={{
					px: 3,
					display: 'flex',
					flexDirection: 'column',
					maxWidth: '1400px',
					width: { xs: '100%', sm: 'auto' },
				}}
			>
				<Stack
					display='flex'
					spacing={5}
					sx={{
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					<Box
						sx={{
							display: 'flex',
							width: '100%',
							justifyContent: 'center',
							alignItems: 'center',
							zIndex: 1,
						}}
					>
						<Stack
							display='flex'
							spacing={3}
							maxWidth='720px'
							sx={{
								alignItems: 'center',
							}}
						>
							<Typography
								variant='h2'
								sx={{
									textAlign: 'center',
								}}
							>
								Plans for{' '}
								<Box
									component='span'
									sx={{
										background:
											'linear-gradient(90deg, #1B8374 40.13%, #1EC86A 99.7%)',
										backgroundClip: 'text',
										WebkitBackgroundClip: 'text',
										WebkitTextFillColor: 'transparent',
									}}
								>
									job postings
								</Box>
							</Typography>
							<Typography
								variant='body1'
								sx={{
									color: 'text.icon',
									textAlign: 'center',
								}}
							>
								Advertise your current job openings across our platform for
								maximum visibility. Target thousands of physicians, PAs, and
								nurse practitioners using Ivy to find their next job. Check out
								our{' '}
								<RouteLink to='#StateImpressions_Container'>
									Search Insights
								</RouteLink>{' '}
								tool below to see where clinicians are looking.
							</Typography>
							{/* <TextSwitch /> */}

							<Stack
								direction='row'
								spacing={1}
								sx={{
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'center',
								}}
							>
								<Typography variant='body1' fontWeight='bold'>
									Monthly
								</Typography>
								<Switch
									checked={yearly}
									color='secondary'
									onChange={() => setYearly((val) => !val)}
								/>
								<Typography variant='body1' fontWeight='bold'>
									Yearly -10%
								</Typography>
							</Stack>
						</Stack>
					</Box>
					<Box
						sx={{
							display: 'flex',
							width: { xs: '100%', sm: 'auto' },
							justifyContent: 'center',
						}}
					>
						<Box
							display='flex'
							sx={{ flexDirection: { xs: 'column', lg: 'row' } }}
						>
							<PlanCard
								title='Starter plan'
								features={[
									{ included: true, text: 'Includes 1 job posting slot*' },
									{ included: true, text: 'Includes all free features' },
									{
										included: true,
										text: 'Your facility will appear first in search results',
									},
									{
										included: true,
										text: 'Automatically renews until cancelled',
									},
								]}
								buttonProps={{
									variant: 'outlined',
									color: 'secondary',
									sx: {
										px: 4,
										py: 1.5,
									},
									onClick: () => handleClick(1),
								}}
								crossPriceSubText={yearly}
								basePrice={yearly ? basePrice?.amount : undefined}
								planCount={
									yearly && prices
										? `Total $${formatInteger(
												calculateDiscountAmount(
													prices,
													1,
													JobPostPriceInterval.YEARLY,
												),
										  )} yearly`
										: undefined
								}
								price={
									prices
										? formatInteger(
												calculateDiscountedPrice(prices, 1, interval),
										  )
										: 0
								}
								priceSubText='/month'
								sx={{
									width: 'auto',
									maxWidth: '368px',
									mt: { xs: 'inherit', lg: 'auto' },
								}}
							/>
							<PlanCard
								chipTitle
								title='Pro plan'
								features={[
									{
										included: true,
										text: 'Bulk discount on job posting slots*',
									},
									{ included: true, text: 'Includes all free features' },
									{
										included: true,
										text: 'Your facility will appear first in search results',
									},
									{
										included: true,
										text: 'Automatically renews until cancelled',
									},
								]}
								buttonProps={{
									variant: 'contained',
									color: 'secondary',
									sx: {
										px: 4,
										py: 1.5,
									},
									onClick: () => handleClick(sliderValue),
								}}
								price={
									prices
										? formatInteger(
												calculateDiscountedPrice(
													prices,
													getJobPostValRep(sliderValue),
													interval,
												),
										  )
										: 0
								}
								crossPriceSubText
								basePrice={basePrice?.amount}
								priceSubText='per job posting'
								planCount={`${getJobPostValRep(sliderValue)} ${
									yearly ? 'monthly ' : ''
								} slots ${
									yearly
										? ''
										: `
									/ Total $${
										prices
											? formatInteger(
													calculateDiscountAmount(
														prices,
														getJobPostValRep(sliderValue),
														interval,
													),
											  )
											: 0
									} ${interval.toLowerCase()}`
								}`}
								planSubText={
									yearly
										? `
									Total $${
										prices
											? formatInteger(
													calculateDiscountAmount(
														prices,
														getJobPostValRep(sliderValue),
														interval,
													),
											  )
											: 0
									} ${interval.toLowerCase()}`
										: ''
								}
								sliderProps={{
									onChange: (env, val) => handleSliderChange(val),
									value: sliderValue,
									min: 2,
									max: billingSliderMarks.length - 1,
									step: 1,
									color: 'secondary',
								}}
								sx={{
									width: 'auto',
									maxWidth: '368px',
									mt: { xs: 3, lg: 'auto' },
									ml: { xs: 0, lg: 3 },
								}}
							/>
							<PlanCard
								badge='Most value'
								title='Enterprise plan'
								features={[
									{
										included: true,
										text: 'Job posting slots* based on your needs',
									},
									{ included: true, text: 'Includes all free features' },
									{
										included: true,
										text: 'Automatically renews until cancelled',
									},
									{
										included: true,
										text: 'Streamline your workflow with ATS integrations, XML job feeds, and web scrapers for your in-house job board',
									},
								]}
								buttonProps={{
									variant: 'outlined',
									color: 'secondary',
									sx: {
										px: 4,
										py: 1.5,
									},
									onClick: () => handleClick(0),
								}}
								buttonText='Contact Us'
								sx={{
									width: 'auto',
									maxWidth: '368px',
									mt: { xs: 3, lg: 'auto' },
									ml: { xs: 0, lg: 3 },
								}}
							/>
						</Box>
					</Box>
					<Box maxWidth='567px'>
						<Typography
							variant='body1'
							sx={{
								color: 'text.icon',
								textAlign: 'center',
							}}
						>
							*Job posting slots are the number of job listings you can have
							active simultaneously. You can deactivate postings to make room
							for new ones whenever you need.
						</Typography>
					</Box>
				</Stack>
			</Box>
		</Box>
	);
};

export default JobPostPlans;
