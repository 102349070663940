import React, {
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';

import { useQuery } from '@apollo/client';
import {
	MarkerClusterer,
	type SuperClusterOptions,
} from '@googlemaps/markerclusterer';
import { Close } from '@mui/icons-material';
import {
	Box,
	Button,
	type SxProps,
	type Theme,
	useMediaQuery,
	useTheme,
} from '@mui/material';
import GoogleMapReact from 'google-map-react';
import mixpanel from 'mixpanel-browser';
import { Helmet } from 'react-helmet';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useDeepCompareEffect } from 'use-deep-compare';
import { useDebounce } from 'usehooks-ts';

import {
	type Profession,
	PROFESSION2PROFVERBOSE,
	PROFESSION2SLUG,
} from '@ivy/constants/clinician';
import {
	stateAbbreviations,
	stateNamesToAbbreviations,
} from '@ivy/constants/location';
import { useCurrentAccount } from '@ivy/gql/hooks';
import { gql } from '@ivy/gql/types';
import { type Facility_Bool_Exp, type Scalars } from '@ivy/gql/types/graphql';
import {
	useAnalyticsObserver,
	usePrevious,
	useStringifiedMemo,
} from '@ivy/lib/hooks';
import {
	BOOTSTRAP_URL_KEYS,
	type Bounds,
	type CompleteLocation,
	type CompletePlaceLocation,
	type Coordinates,
	getBoundsZoomLevel,
	getCappedZoom,
	type ViewportPreciseLocation,
	type ZoomPreciseLocation,
} from '@ivy/lib/services/maps';
import { buildInternalLink } from '@ivy/lib/util/route';
import { isCrawler } from '@ivy/lib/util/userAgent';

import { type MapListItemObject, type UnpreparedIcon } from './common';
import DesktopSearchResults from './DesktopSearchResults';
import FiltersBar from './filters/FiltersBar';
import FiltersPopup from './filters/FiltersPopup';
import useMapContext from './MapProvider/useMapContext';
import {
	defaultClusterIcon,
	defaultClusterShadowIcon,
	defaultFeaturedClusterIcon,
	defaultFeaturedClusterShadowIcon,
	defaultFeaturedIcon,
	defaultFeaturedShadowIcon,
	defaultPrimaryIcon,
	defaultPrimaryShadowIcon,
} from './markers';
import MobileSearchResults from './MobileSearchResults';
import { type NearbyMapListItemObject } from './NearbyCarousel';
import SearchBar, { type SearchBarProps } from './SearchBar';
import SearchInstructions, {
	HIDE_SEARCH_INSTRUCTIONS_KEY,
} from './SearchInstructions';
import SearchPreview from './SearchPreview';

// We rename the postings field of contracts to prevent a bug where conflicting names causing an infinite refetch

const PAGE_SIZE = 50;

const searchMapOptions =
	(minZoom?: number, maxZoom?: number) => (maps: GoogleMapReact.Maps) => ({
		mapTypeControl: false,
		streetViewControl: false,
		fullscreenControl: false,
		maxZoom: maxZoom,
		minZoom: minZoom,
		gestureHandling: 'greedy',
		keyboardShortcuts: false,
		zoomControlOptions: {
			position: maps.ControlPosition.RIGHT_BOTTOM,
		},
	});

export interface BaseMapItemObject extends MapListItemObject {
	location: Scalars['geography'];
}

export type NearbyBaseMapItemObject = NearbyMapListItemObject;

interface MapRef {
	map: google.maps.Map;
	maps: typeof google.maps;
	ref: Element | null;
}

export type MapLocation =
	| CompletePlaceLocation
	| ViewportPreciseLocation
	| ZoomPreciseLocation;

type GMarker = google.maps.Marker & {
	id: string;
	featured?: boolean;
};

const prepareIcon = (
	marker: UnpreparedIcon,
	mapRef: MapRef,
): google.maps.Icon => {
	return {
		...marker,
		size: marker.size
			? new mapRef.maps.Size(marker.size.width, marker.size.height)
			: undefined,
		origin: marker.origin
			? new mapRef.maps.Point(marker.origin.x, marker.origin.y)
			: undefined,
		anchor: marker.anchor
			? new mapRef.maps.Point(marker.anchor.x, marker.anchor.y)
			: undefined,
	};
};

const BaseMap_BoundaryQDoc = gql(/* GraphQL */ `
	query BaseMap_Boundary($boundaryId: uuid!) {
		boundary: place_boundary_by_pk(id: $boundaryId) {
			id
			boundaryGeoJson: boundary_geojson
		}
	}
`);

export interface BaseMapProps<
	T extends BaseMapItemObject,
	V extends NearbyBaseMapItemObject,
> {
	entityType: string;
	slug: string;
	profession: Profession;
	showAlts?: boolean;
	data?: T[];
	nearbyData?: V[];
	nearbyTitle?: string;
	numNearbyRows?: number;
	selectable?: boolean;
	selected?: (T | V)[];
	clustering?: boolean;
	types?: SearchBarProps<false>['types'];
	defaultLocation?: MapLocation;
	minZoom?: number;
	maxZoom?: number;
	/** Prevents the map from zooming too far in when provided with a viewport */
	maxViewportZoom?: number;
	baseRoute: string;
	pageRoute: string;
	entityFilter?: Facility_Bool_Exp;
	pageTitleComponent?: React.ElementType;
	hideProfession?: boolean;
	showInstructions?: boolean;
	resolveTitle?: (loc?: string, prof?: string) => string;
	resolveHelmet?: (loc?: string, prof?: string) => JSX.Element | null;
	onChangeSelected?: (newSelection: (T | V)[]) => void;
	onExpanded?: (expand: boolean) => void;
	highlightFeatured?: boolean;
	disableCrawlerPlaceIdRestriction?: boolean;
	showStateLines?: boolean;

	// Map Card
	badgeIcon?: JSX.Element;
	badgeSxProps?: SxProps<Theme>;

	// Ads
	dataTSResolver?: (prof: Profession, id: string) => string;
	slotId?: string;

	// Markers
	primaryIcon?: UnpreparedIcon;
	primaryShadowIcon?: UnpreparedIcon;
	featuredIcon?: UnpreparedIcon;
	featuredShadowIcon?: UnpreparedIcon;
	clusterIcon?: UnpreparedIcon;
	clusterShadowIcon?: UnpreparedIcon;
	featuredClusterIcon?: UnpreparedIcon;
	featuredClusterShadowIcon?: UnpreparedIcon;
}

const BaseMap = <
	T extends BaseMapItemObject,
	V extends NearbyBaseMapItemObject,
>({
	entityType,
	slug,
	profession,
	showAlts,
	data,
	nearbyData,
	numNearbyRows,
	nearbyTitle,
	selectable,
	selected,
	onChangeSelected,
	clustering = false,
	types = ['place', 'facility', 'org'],
	defaultLocation,
	minZoom,
	maxZoom,
	maxViewportZoom,
	baseRoute,
	pageRoute,
	pageTitleComponent,
	hideProfession,
	showInstructions = false,
	resolveTitle,
	resolveHelmet,
	onExpanded,
	highlightFeatured,
	disableCrawlerPlaceIdRestriction,
	showStateLines = false,

	badgeIcon,
	badgeSxProps,

	// Ads
	dataTSResolver,
	slotId,

	// Markers
	primaryIcon: unmemoPrimaryIcon = defaultPrimaryIcon,
	primaryShadowIcon: unmemoPrimaryShadowIcon = defaultPrimaryShadowIcon,
	featuredIcon: unmemoFeaturedIcon = defaultFeaturedIcon,
	featuredShadowIcon: unmemoFeaturedShadowIcon = defaultFeaturedShadowIcon,
	clusterIcon: unmemoClusterIcon = defaultClusterIcon,
	clusterShadowIcon: unmemoClusterShadowIcon = defaultClusterShadowIcon,
	featuredClusterIcon: unmemoFeaturedClusterIcon = defaultFeaturedClusterIcon,
	featuredClusterShadowIcon:
		unmemoFeaturedClusterShadowIcon = defaultFeaturedClusterShadowIcon,
}: BaseMapProps<T, V>) => {
	// Prevent unnecessary re-renders from objects
	const primaryIcon = useStringifiedMemo(unmemoPrimaryIcon);
	const primaryShadowIcon = useStringifiedMemo(unmemoPrimaryShadowIcon);
	const featuredIcon = useStringifiedMemo(unmemoFeaturedIcon);
	const featuredShadowIcon = useStringifiedMemo(unmemoFeaturedShadowIcon);
	const clusterIcon = useStringifiedMemo(unmemoClusterIcon);
	const clusterShadowIcon = useStringifiedMemo(unmemoClusterShadowIcon);
	const featuredClusterIcon = useStringifiedMemo(unmemoFeaturedClusterIcon);
	const featuredClusterShadowIcon = useStringifiedMemo(
		unmemoFeaturedClusterShadowIcon,
	);
	const theme = useTheme();
	const navigate = useNavigate();
	// Indicates that the center, zoom, and location fields have been configured. Usually, the zoom needs to be
	// determined from location.viewport using the height/width of the map div before we can proceed.
	const [initialized, setInitialized] = useState(false);
	// Indicates that the initial list of entities has been fetched
	const fetchedRef = useRef(false);
	// Use state instead of refs b/c we need to re-run some effects when the refs are finally assigned, and using
	// ref.current to do that is an antipattern
	// https://epicreact.dev/why-you-shouldnt-put-refs-in-a-dependency-array/
	// https://stackoverflow.com/questions/60476155/is-it-safe-to-use-ref-current-as-useeffects-dependency-when-ref-points-to-a-dom
	const [mapRef, setMapRef] = useState<MapRef | null>(null);
	const isLtMd = useMediaQuery(theme.breakpoints.down('md'), { noSsr: true });
	const currAcc = useCurrentAccount();
	const [searchParams, setSearchParams] = useSearchParams();
	const [location, setLocation] = useState<MapLocation | null>(() => {
		if (searchParams.has('location')) {
			return JSON.parse(searchParams.get('location')!) as MapLocation;
		}
		return defaultLocation || null;
	});
	const locationRef = useRef<typeof location>(location);
	useEffect(() => {
		locationRef.current = location;
	});
	let region: string | undefined = undefined;
	if (location?.label) {
		// Special case for DC
		if (
			'placeId' in location &&
			location.placeId &&
			['ChIJW-T2Wt7Gt4kRmKFUAsCO4tY', 'ChIJW-T2Wt7Gt4kRKl2I1CJFUsI'].includes(
				location.placeId,
			)
		) {
			// DC has a locality place ID and a administrative_area_1 ID
			// It can occur under the names "Washington D.C.", "Washington D.C., DC", "District of Columbia," "District of Columbia, DC", etc...
			region = 'DC';
		} else {
			const locationLabelParts = location.label.split(', ');
			if (locationLabelParts.length === 1) {
				// E.g. North Carolina
				// Might be undefined
				region = stateNamesToAbbreviations[locationLabelParts[0]];
			} else {
				// E.g. Raleigh, NC
				const possibleRegion =
					locationLabelParts[locationLabelParts.length - 1];
				if (stateAbbreviations.includes(possibleRegion)) {
					region = possibleRegion;
				}
			}
		}
	}
	// Reflect changes in URL so it can be shared and gone back to. Use center/zoom not bounding box since client can change screen size
	const [center, setCenter] = useState<Coordinates>(() => {
		if (searchParams.has('centerX') && searchParams.has('centerY')) {
			return {
				lng: Number(searchParams.get('centerX')),
				lat: Number(searchParams.get('centerY')),
			};
		}
		if (location) {
			// Location always has a center
			return location.center;
		}
		// Default - Capital Club Raleigh Founded - (35.77728126553413, -78.64053731528638)
		return {
			lng: -78.64053731528638,
			lat: 35.77728126553413,
		};
	});
	// Used in effects where we want the effect to run every time zoom changes
	const [zoom, setZoom] = useState(() => {
		if (searchParams.has('zoom')) {
			return getCappedZoom(Number(searchParams.get('zoom')), minZoom, maxZoom);
		}
		if (location && 'zoom' in location) {
			// Location may not have zoom but viewport instead
			// That situation is handled in the useEffect below since we need GMaps to
			// be loaded first
			return getCappedZoom(location.zoom, minZoom, maxZoom);
		}
		return 10;
	});
	const zoomRef = useRef<typeof zoom>(zoom);
	useEffect(() => {
		zoomRef.current = zoom;
	}, [zoom]);
	const { queryResponse, setQueryOptions, entityFilters } = useMapContext<
		unknown,
		unknown,
		T,
		V
	>();
	const [filtersOpen, setFiltersOpen] = useState(false);
	const {
		filters,
		rawFilters,
		appliedFilters,
		setRawFilters,
		apiFilters,
		applyFilters,
		resetFilters,
		discardFilters,
		filtersCount,
		serializedFilters,
	} = entityFilters;
	const [bounds, setBounds] = useState<GoogleMapReact.Bounds | null>(null);
	// To test render performance, set this to 0 and start panning rapidly on desktop/mobile
	const debouncedBounds = useDebounce(bounds, 700);
	const searchBounds: Bounds | null = useMemo(() => {
		if (!debouncedBounds) {
			return null;
		}
		return {
			xMin: debouncedBounds.nw.lng,
			xMax: debouncedBounds.se.lng,
			yMin: debouncedBounds.se.lat,
			yMax: debouncedBounds.nw.lat,
		};
	}, [debouncedBounds]);
	// If is a google crawler we want to show all listed entities on mobile menu
	const [expanded, setExpanded] = useState(isCrawler);
	const gmarkers = useRef<GMarker[]>([]);
	const [mapContainerRef, setMapContainerRef] = useState<HTMLDivElement | null>(
		null,
	);
	// Entity to preview
	const [clicked, setClicked] = useState<string | null>(null);

	// ID of a highlighted entity (hover your mouse of a search result)
	const [highlighted, setHighlighted] = useState<string | null>(null);

	// TODO: put into parent
	const [displayInstructions, setDisplayInstructions] = useState(
		() =>
			// Hasn't seen the instructions yet
			localStorage.getItem(HIDE_SEARCH_INSTRUCTIONS_KEY) !== 'true' &&
			// Anonymous or a clinician
			(!currAcc || currAcc.isClinician) &&
			// No flag recorded in the DB not to show the instructions
			!currAcc?.hideSearchInstructions,
	);

	const clusterer = useRef<MarkerClusterer | null>(null);
	// useDeepCompareEffect crashes if you pan/zoom the map around with a maximum call stack exceeded error, presumably
	// because of non-plain objects (e.g. refs, functions, etc...)
	useEffect(() => {
		if (!mapRef) {
			return;
		}
		gmarkers.current.forEach((marker) => {
			// Notice we compare by URL since we can't compare object to object (GMaps is cloning the icon object)
			const markerIconUrl = (marker.getIcon() as UnpreparedIcon)?.url;
			if (
				selected?.some((el) => el.id === marker.id) ||
				marker.id === highlighted
			) {
				// Marker is selected or highlighted
				if (!marker.featured && markerIconUrl !== primaryShadowIcon.url) {
					// Update the marker icon if need be
					marker.setIcon(prepareIcon(primaryShadowIcon, mapRef));
				} else if (
					marker.featured &&
					markerIconUrl !== featuredShadowIcon.url
				) {
					// Update the marker icon if need be
					marker.setIcon(prepareIcon(featuredShadowIcon, mapRef));
				}
				// Otherwise, do nothing
			} else if (marker.featured && markerIconUrl !== featuredIcon.url) {
				// Default marker
				marker.setIcon(prepareIcon(featuredIcon, mapRef));
			} else if (!marker.featured && markerIconUrl !== primaryIcon.url) {
				// Featured marker
				marker.setIcon(prepareIcon(primaryIcon, mapRef));
			}

			// TODO: bounce animation is stupid, find some other representation
			if (marker.id === clicked) {
				if (!marker.getAnimation()) {
					marker.setAnimation(mapRef.maps.Animation.BOUNCE);
				}
				// Otherwise, do nothing
			} else if (marker.getAnimation()) {
				marker.setAnimation(null);
			}
		});
		// Currently no bounce animation for clusters
		// @ts-ignore
		clusterer.current?.clusters.forEach((cluster) => {
			// If length is 1, then the marker is the entitiy marker and shouldn't be modified
			if (cluster.markers && cluster.markers.length > 1) {
				const isHighlighted = !!cluster.markers?.find(
					(marker) => (marker as GMarker).id === highlighted,
				);
				const isSelected = !!cluster.markers?.find(
					(marker) => selected?.some((el) => el.id === (marker as GMarker).id),
				);
				const isFeatured = !!cluster.markers?.find(
					(marker) => (marker as GMarker).featured,
				);
				const clusterMarker = cluster.marker as google.maps.Marker;
				const clusterMarkerIconUrl = (clusterMarker.getIcon() as UnpreparedIcon)
					?.url;
				if (isSelected || isHighlighted) {
					// Make sure icon is shadowed if it isn't already
					if (!isFeatured && clusterMarkerIconUrl !== clusterShadowIcon.url) {
						clusterMarker.setIcon(prepareIcon(clusterShadowIcon, mapRef));
					} else if (
						isFeatured &&
						clusterMarkerIconUrl !== featuredClusterShadowIcon.url
					) {
						clusterMarker.setIcon(
							prepareIcon(featuredClusterShadowIcon, mapRef),
						);
					}
					// Otherwise, do nothing
				} else if (
					isFeatured &&
					clusterMarkerIconUrl !== featuredClusterIcon.url
				) {
					clusterMarker.setIcon(prepareIcon(featuredClusterIcon, mapRef));
				} else if (!isFeatured && clusterMarkerIconUrl !== clusterIcon.url) {
					clusterMarker.setIcon(prepareIcon(clusterIcon, mapRef));
				}
			}
		});
	}, [
		gmarkers,
		mapRef,
		highlighted,
		selected,
		clicked,
		clusterer,
		clusterIcon,
		clusterShadowIcon,
		featuredClusterIcon,
		featuredClusterShadowIcon,
		featuredIcon,
		featuredShadowIcon,
		primaryIcon,
		primaryShadowIcon,
	]);

	const [disableBoundary, setDisabledBoundary] = useState(
		() =>
			showStateLines &&
			searchParams.get('disableBoundary')?.toLowerCase() === 'true',
	);
	const handleClickDisableBoundary = useCallback(() => {
		setDisabledBoundary(true);
	}, [setDisabledBoundary]);
	const boundaryId =
		(location && 'boundaryId' in location && location?.boundaryId) || null;
	const stateName =
		(location &&
			'geocodeTypes' in location &&
			location.geocodeTypes?.includes('administrative_area_level_1') &&
			'geocodeShortName' in location &&
			location.geocodeShortName) ||
		null;
	const stateFilter = useMemo(() => {
		if (showStateLines) {
			// Check `boundaryId` and not `boundary` since `boundary` is fetched async from the backend
			return boundaryId && !disableBoundary ? stateName : null;
		}
		// Use `undefined` when `showStateLines` is not enabled so the filter is not sent to the backend, as the
		// backend function may not handle such an argument.
		return undefined;
	}, [showStateLines, boundaryId, disableBoundary, stateName]);
	const stateFilterRef = useRef(stateFilter);
	useEffect(() => {
		stateFilterRef.current = stateFilter;
	}, [stateFilter]);
	const { data: boundaryData } = useQuery(BaseMap_BoundaryQDoc, {
		variables: {
			boundaryId,
		},
		skip: !showStateLines || disableBoundary || !boundaryId,
	});
	const boundary = useMemo(() => {
		const placeBoundary = boundaryData?.boundary;
		if (!placeBoundary?.boundaryGeoJson) {
			return null;
		}
		return {
			id: placeBoundary.id,
			// Wrap the geojson polygon in a feature
			geojson: {
				type: 'Feature',
				geometry: JSON.parse(placeBoundary.boundaryGeoJson),
			},
		};
	}, [boundaryData]);
	const mapFeaturesRef = useRef<google.maps.Data.Feature[]>();
	useEffect(() => {
		if (!mapRef?.map) {
			return;
		}
		mapFeaturesRef.current?.forEach((el) => mapRef.map.data.remove(el));
		if (boundary) {
			mapFeaturesRef.current = mapRef.map.data.addGeoJson(boundary.geojson);
			mapRef.map.data.setStyle({
				fillColor: 'transparent',
				strokeColor: theme.palette.primary.dark,
				strokeWeight: 2,
			});
		}
	}, [mapRef, mapFeaturesRef, boundary, theme]);

	// Important that this is a boolean (see use of !!) since it's used as a hook dependency
	const isReadyToFetch =
		initialized && (!!searchBounds || (isCrawler && !!location));
	useDeepCompareEffect(() => {
		// We use `locationRef`, `zoomRef`, and `stateFilterRef` here instead of their concrete values because we
		// don't want this effect to run when those values are changed. We really only want to re-run this query
		// if the `searchBounds`, `apiFilters`, or `profession` changes. Here are some scenarios.
		//   - When someone changes the zoom levels, the searchBounds will change a moment later. If we didn't use
		//     a ref, we would get a double fetch.
		//   - Similar for changing a location thru the search bar. The location is set and the searchBounds are
		//     updated moments later.
		//   - Finally, `stateFilterRef` depends on `location`, so it should also be a ref.
		// Additionally, since the useEffect hooks for `zoomRef`, `locationRef`, and `stateFilterRef` are defined
		// before this effect, the values of `<ref>.current` will be up-to-date by the time this effect runs.
		setQueryOptions({
			variables:
				isCrawler &&
				locationRef.current &&
				'viewport' in locationRef.current &&
				locationRef.current.viewport
					? {
							xMin: locationRef.current.viewport.xMin,
							yMin: locationRef.current.viewport.yMin,
							xMax: locationRef.current.viewport.xMax,
							yMax: locationRef.current.viewport.yMax,
							// For the crawler, when viewing Raleigh, NC, don't show entities outside the city
							// even if they're nearby, like Garner. For non-crawlers, this is OK. We use the
							// placeId field to limit this. No need to use this with the state restriction, as it
							// would be redundant.
							placeId:
								!disableCrawlerPlaceIdRestriction &&
								'placeId' in locationRef.current &&
								locationRef.current.placeId
									? locationRef.current.placeId
									: undefined,
							filters: apiFilters,
							profession: profession,
							includeNearby: false,
					  }
					: {
							xMin: searchBounds?.xMin,
							yMin: searchBounds?.yMin,
							xMax: searchBounds?.xMax,
							yMax: searchBounds?.yMax,
							filters: apiFilters,
							profession: profession,
							includeNearby: zoomRef.current >= 9,
							// We filter by state instead of a given boundaryId in case the resulting boundary is
							// slightly inaccurate and excludes an entity actually in the state
							state: stateFilterRef.current,
					  },
			// Note we won't become initialized if location & profession aren't in the search params
			skip: !isReadyToFetch,
			notifyOnNetworkStatusChange: true,
		});
	}, [
		locationRef,
		disableCrawlerPlaceIdRestriction,
		apiFilters,
		profession,
		searchBounds,
		zoomRef,
		stateFilterRef,
		// We rely on any change in `showStateLines` and `disableBoundary` instead of `stateFilterRef` to get this
		// effect to re-run when a boundary is removed
		showStateLines,
		disableBoundary,
		isReadyToFetch,
	]);
	const { loading, refetch } = queryResponse;

	// Either a UUID or undefined
	const currAccId = currAcc?.id;
	// Initially undefined
	const prevCurrAccId = usePrevious(currAccId);
	useEffect(() => {
		// When a user logs in, they might have already applied to several entities which now need to be disabled
		// currAcc might change periodically in the application, so to avoid running this effect over and over again,
		// we instead check the ID.
		// Note refetch ignores our skip condition in useQuery, so we recheck here.
		if (isReadyToFetch && currAccId !== prevCurrAccId) {
			refetch();
		}
	}, [isReadyToFetch, currAccId, prevCurrAccId, refetch]);

	const changeZoom = useCallback(
		(newZoom: number) => {
			const capped = getCappedZoom(newZoom, minZoom, maxZoom);
			setZoom(capped);
		},
		[setZoom, minZoom, maxZoom],
	);

	const entities: T[] = useMemo(() => {
		if (!data) {
			return [];
		}
		return data;
	}, [data]);

	const nearbyEntities: V[] = useMemo(() => {
		if (!nearbyData) {
			return [];
		}
		return nearbyData;
	}, [nearbyData]);

	// updates the applied status for entities whenever a user logs in
	useEffect(() => {
		// This effect enables us to go directly to a page such as
		// http://localhost:3000/emergency-medicine/physician-assistant/nc/2
		// while also resetting the page to 1 whenever the user pans or zooms to new bounds
		if (!data) {
			// Haven't done our initial fetch
			return;
		} else if (!fetchedRef.current) {
			// We've done our initial fetch. The next time this effect occurs, we will reset the page to 1 since
			// that indicates the user is moving to new bounds.
			fetchedRef.current = true;
		} else {
			// User has changed their search, causing a new set of entities to be loaded
			// Reset the page to 1
			navigate(
				{
					pathname: buildInternalLink(baseRoute, {
						profession: PROFESSION2SLUG[profession],
						slug,
					}),
					search: searchParams.toString(),
				},
				{
					replace: true,
				},
			);
		}
	}, [data, fetchedRef, navigate, baseRoute, profession, slug, searchParams]);

	useEffect(() => {
		if (!mapRef) {
			return;
		}
		const entityLookup = new Map<string, T>();
		entities.forEach((ent) => entityLookup.set(ent.id, ent));
		const markerLookup = new Map<string, GMarker>();
		const newMarkers: GMarker[] = [];

		if (clustering && !clusterer.current) {
			clusterer.current = new MarkerClusterer({
				map: mapRef.map,
				markers: [],
				algorithmOptions: {
					// https://www.npmjs.com/package/supercluster
					maxZoom: 8,
					// This works well on 40px icons to prevent overlap
					radius: 80,
				} as SuperClusterOptions,
				renderer: {
					render: ({ count, position, markers }) => {
						const isFeatured = (markers as GMarker[]).some((el) => el.featured);
						return new mapRef.maps.Marker({
							position,
							icon: prepareIcon(
								isFeatured ? featuredClusterIcon : clusterIcon,
								mapRef,
							),
							label: {
								text: count.toString(),
								color: 'rgba(255,255,255,0.9)',
								fontSize: theme.typography.body2.fontSize?.toString(),
								fontWeight: 'bold',
								fontFamily: theme.typography.fontFamily,
							},
							// Note this is zIndex has its own stacking context and won't affect other parts of the app
							zIndex: Number(mapRef.maps.Marker.MAX_ZINDEX) + count,
						});
					},
				},
			});
		}

		// Remove markers that should no longer be shown
		gmarkers.current.forEach((marker) => {
			if (entityLookup.has(marker.id)) {
				markerLookup.set(marker.id, marker);
				newMarkers.push(marker);
				return;
			}
			// Don't redraw right now until entire batch processed
			clusterer.current?.removeMarker(marker, true);
			marker.setMap(null);
			mapRef.maps.event.clearInstanceListeners(marker);
		});

		// Add new markers
		entities.forEach((entity) => {
			if (markerLookup.has(entity.id)) {
				// Already present
				return;
			}
			const marker = new mapRef.maps.Marker({
				position: {
					lat: entity.location.coordinates[1],
					lng: entity.location.coordinates[0],
				},
				map: mapRef.map,
				icon: entity.featured
					? prepareIcon(featuredIcon, mapRef)
					: prepareIcon(primaryIcon, mapRef),
			}) as GMarker;
			marker.id = entity.id;
			marker.featured = entity.featured;
			marker.addListener('click', ({ domEvent }) => {
				// Separate this event from a map click event by stopping the propagation
				domEvent.stopPropagation();
				setClicked(entity.id);
			});
			newMarkers.push(marker);
			clusterer.current?.addMarker(marker, true);
		});
		clusterer.current?.render();
		gmarkers.current = newMarkers;
	}, [
		mapRef,
		clustering,
		clusterer,
		theme,
		entities,
		gmarkers,
		setClicked,
		clusterIcon,
		featuredClusterIcon,
		featuredIcon,
		primaryIcon,
	]);

	const handleChangeLocation = useCallback(
		(newLocation: MapLocation) => {
			if (!mapContainerRef) {
				return;
			}
			if ('viewport' in newLocation) {
				const { viewport } = newLocation;
				// Use the center determined by the viewport rather than the center set in the location, as they might
				// be different. The viewport is more accurate to what we're trying to display rather than the "center
				// of gravity," which seems to be what the `center` in a Google Maps location implies.
				setCenter({
					lat: (viewport.yMin + viewport.yMax) / 2,
					lng: (viewport.xMin + viewport.xMax) / 2,
				});
				changeZoom(
					getBoundsZoomLevel(
						viewport,
						{
							height: Math.max(mapContainerRef.clientHeight || 0, 1),
							width: Math.max(mapContainerRef.clientWidth || 0, 1),
						},
						maxZoom !== undefined || maxViewportZoom !== undefined
							? Math.min(
									maxZoom ?? Number.POSITIVE_INFINITY,
									maxViewportZoom ?? Number.POSITIVE_INFINITY,
							  )
							: undefined,
					),
				);
			} else {
				setCenter(newLocation.center);
				if ('zoom' in newLocation) {
					changeZoom(newLocation.zoom);
				}
			}
			setDisabledBoundary(false);
			setLocation(newLocation);
		},
		[
			setLocation,
			setCenter,
			maxZoom,
			maxViewportZoom,
			changeZoom,
			mapContainerRef,
			setDisabledBoundary,
		],
	);

	const handleSubmitSearch = useCallback(
		(newLocation: CompleteLocation, newProfession: Profession | '') => {
			if ('url' in newLocation) {
				navigate(newLocation.url);
			} else {
				navigate(
					{
						pathname: buildInternalLink(baseRoute, {
							profession: PROFESSION2SLUG[newProfession as Profession],
							slug: 'slug' in newLocation ? newLocation.slug! : 'search',
						}),
						search: searchParams.toString(),
					},
					{
						replace: true,
					},
				);
				handleChangeLocation(newLocation as MapLocation);
			}
		},
		[handleChangeLocation, navigate, baseRoute, searchParams],
	);

	useEffect(() => {
		// This effect sets the correct viewport for viewing a GMaps Place if necessary
		// You can test this by searching a state from the ForClinicians page and same with a city. They should
		// each have different zoom levels instead of the default of "10"
		if (!mapContainerRef || initialized) {
			return;
		}

		if (location && 'viewport' in location && !searchParams.has('zoom')) {
			// Only run if we need to do a viewport check
			// E.g. a user could've searched Raleigh, but then panned to Durham and shared that link. In that case,
			// we should not run handleChangeLocation since that would move the view to Raleigh.
			handleChangeLocation(location);
		}

		setInitialized(true);
	}, [
		mapContainerRef,
		initialized,
		location,
		searchParams,
		handleChangeLocation,
		setInitialized,
	]);

	useEffect(() => {
		// Don't overwrite the searchparams if not initialized yet because those are still needed until that point
		// or we may redirect away from this page
		if (!initialized) {
			return;
		}
		setSearchParams(
			{
				centerX: center.lng.toString(),
				centerY: center.lat.toString(),
				zoom: zoom.toString(),
				// Don't include `disableBoundary` param if there is no `boundaryId` to begin with or `showStateLines`
				// is disabled.
				...(showStateLines && boundaryId
					? {
							disableBoundary: disableBoundary.toString(),
					  }
					: {}),
				// Only include the location if we're looking at a place that is not in our gmaps_link database
				...(location && !('slug' in location)
					? { location: JSON.stringify(location) }
					: {}),
				// Same idea with filters
				...(serializedFilters !== JSON.stringify({})
					? { filters: serializedFilters }
					: {}),
			},
			{
				// Replace last entry in history stack so that back button works properly - goes to a different page,
				// not a different part of the map
				replace: true,
			},
		);
	}, [
		initialized,
		setSearchParams,
		center,
		zoom,
		showStateLines,
		boundaryId,
		disableBoundary,
		location,
		serializedFilters,
	]);

	useEffect(() => {
		// When we search a new location, first `location` is set (triggers a re-render) and then `searchBounds`
		// are set (triggers another re-render). This would mean that, if we used both as a dependency for `useEffect`,
		// a tracking event would fire twice. Thus, we choose to ignore location and access it via a ref, while
		// looking to `searchBounds` to see if state has changed, in addition to `entityType`, `profession`, and
		// `filters`.
		// Test going from Raleigh, NC to North Carolina to make sure only one event is fired off
		// We tried looking at `data`, but it has weird behavior from Apollo cache that typically results in 2 changes
		// when it only changed once.
		// Opt: May no longer be the case, `fetchMore` has been removed.
		if (!isReadyToFetch) {
			return;
		}
		mixpanel.track('BaseMap_ViewResults_v1', {
			specialty: 'EMERGENCY_MEDICINE',
			entity_type: entityType,
			profession,
			location: locationRef.current,
			filters: appliedFilters,
			bounds: searchBounds,
			state: stateFilterRef.current,
		});
	}, [
		isReadyToFetch,
		entityType,
		profession,
		locationRef,
		appliedFilters,
		searchBounds,
		stateFilterRef,
		// We rely on any change in `showStateLines`/`disableBoundary` instead of `stateFilterRef` to get this effect
		// to re-run when a boundary is removed
		showStateLines,
		disableBoundary,
	]);

	// Since we use virtualized scrolling, the intersection observer doesn't know we already viewed something.
	// Thus, we need to check if we're firing off more than one impression if we scroll back to an entity.
	// This variable helps us keep track of what we have already viewed.
	// This also affects going back to a given page of results.
	const impressionsFiredRef = useRef<Set<string>>(new Set());

	useEffect(() => {
		// See above for how we determine if we have a new search. We listen to all state changes except location.
		impressionsFiredRef.current = new Set();
	}, [
		// Same dependencies as ViewResults tracker without the `locationRef` or `stateFilterRef` refs
		impressionsFiredRef,
		entityType,
		profession,
		appliedFilters,
		searchBounds,
		showStateLines,
		disableBoundary,
	]);

	const handleTrackingImpression = useCallback(
		(node: HTMLElement) => {
			if (!node.dataset.trEntityId) {
				return;
			}
			if (impressionsFiredRef.current.has(node.dataset.trEntityId)) {
				return;
			}
			mixpanel.track('BaseMap_EntityImpression_v1', {
				specialty: 'EMERGENCY_MEDICINE',
				entity_type: entityType,
				entity_id: node.dataset.trEntityId,
				profession,
				location,
				filters: appliedFilters,
				bounds: searchBounds,
				state: stateFilter,
				featured: node.dataset.trFeatured === 'true',
				nearby: node.dataset.trNearby === 'true',
			});
			impressionsFiredRef.current.add(node.dataset.trEntityId);
		},
		[
			impressionsFiredRef,
			entityType,
			profession,
			location,
			appliedFilters,
			searchBounds,
			stateFilter,
		],
	);

	const handleTrackingClick = useCallback(
		(node) => {
			if (!node.dataset.trEntityId) {
				return;
			}
			// Mixpanel is logging out that it's sending click events twice per click, but this handler
			// is only called once per click. In reality, the $insert_ids are the same, so it's not getting recorded
			// as 2 distinct events.
			// TODO: We should be tracking clicks to job postings hyperlinks separately
			mixpanel.track('BaseMap_EntityClick_v1', {
				specialty: 'EMERGENCY_MEDICINE',
				entity_type: entityType,
				entity_id: node.dataset.trEntityId,
				profession,
				location,
				filters: appliedFilters,
				bounds: searchBounds,
				state: stateFilter,
				featured: node.dataset.trFeatured === 'true',
				nearby: node.dataset.trNearby === 'true',
			});
		},
		[
			entityType,
			profession,
			location,
			appliedFilters,
			searchBounds,
			stateFilter,
		],
	);

	useAnalyticsObserver(
		'data-tr-entity-id',
		'data-tr-clickable',
		handleTrackingImpression,
		handleTrackingClick,
	);

	const handleUnsetClick = useCallback(() => {
		// To prevent this from being called when clicking on the markers, make sure
		// to use stop propagation there.
		setClicked(null);
	}, [setClicked]);

	const handleMapLoaded = useCallback(
		(mapProps: MapRef) => {
			setMapRef(mapProps);
		},
		[setMapRef],
	);

	useEffect(() => {
		if (!mapRef) {
			return;
		}
		// Don't use onClick on google-map-react, as it causes the following bug:
		//   If start dragging map, pause and start loading in entities, and then continue
		//   to try an drag while entities still fetching, will be unable to drag (stuck)
		// Closure problems - need to pass in the mapProps here and not through state
		mapRef.map.addListener('click', handleUnsetClick);
		return () => mapRef.maps.event.clearListeners(mapRef.map, 'click');
	}, [mapRef, handleUnsetClick]);

	const handleChange = useCallback(
		({
			center: newCenter,
			zoom: newZoom,
			bounds: newBounds,
		}: GoogleMapReact.ChangeEventValue) => {
			setCenter(newCenter);
			changeZoom(newZoom);
			setBounds(newBounds);
		},
		[setCenter, changeZoom, setBounds],
	);

	const handleHoverEnter = useCallback(
		(entityId: string | null) => {
			setHighlighted(entityId);
		},
		[setHighlighted],
	);

	const handleHoverLeave = useCallback(() => {
		setHighlighted(null);
	}, [setHighlighted]);

	const handleClickFilters = useCallback(
		(ev: React.SyntheticEvent) => {
			// Without this, will focus on the searchbar after closing the filters popup, which is bad for mobile as
			// a keyboard pops up
			ev.preventDefault();
			ev.stopPropagation();
			setFiltersOpen(true);
		},
		[setFiltersOpen],
	);

	const handleCloseFilters = useCallback(() => {
		discardFilters();
		setFiltersOpen(false);
	}, [discardFilters, setFiltersOpen]);

	const handleApplyFilters = useCallback(() => {
		applyFilters();
		setFiltersOpen(false);
	}, [applyFilters, setFiltersOpen]);

	const handleResetFilters = useCallback(() => {
		resetFilters();
		setFiltersOpen(false);
	}, [resetFilters, setFiltersOpen]);

	const handleCloseInstructions = useCallback(() => {
		setDisplayInstructions(false);
	}, [setDisplayInstructions]);

	const handleExpanded = (expan: boolean) => {
		setExpanded(expan);
		if (onExpanded) onExpanded(expan);
	};

	const handleSelect = useCallback(
		(entity: T | V) => {
			if (!onChangeSelected) {
				return;
			}
			if (selected?.some((el) => el.id === entity.id)) {
				onChangeSelected(selected.filter((el) => el.id !== entity.id));
			} else {
				onChangeSelected([...(selected || []), entity]);
			}
		},
		[selected, onChangeSelected],
	);

	const selectedRef = useRef<typeof selected>(selected);
	useEffect(() => {
		selectedRef.current = selected;
	}, [selected]);
	const prevEntities = usePrevious(entities);
	const prevNearbyEntities = usePrevious(nearbyEntities);
	useEffect(() => {
		// This effect is helpful after logging in and some of the selections should be unselected if the
		// user has already applied to them due to the `refetch` called in this component. It also resets the
		// selection if the search results have changed or their order has.
		if (!onChangeSelected || !prevEntities) {
			// On the first run, `prevEntities` will be undefined, and we won't want to execute this effect since
			// it would reset the selection
			return;
		}
		const prevMergedData = [...prevEntities, ...(prevNearbyEntities ?? [])];
		const mergedData = [...entities, ...(nearbyEntities || [])];
		// Check if the seach results are the same
		if (
			prevMergedData.length === mergedData.length &&
			prevMergedData.every((el, idx) => el.id === mergedData[idx].id)
		) {
			// If all the search results are the same, we should update the selected data with the latest info
			// in case of stale data. We should also remove any `processed` results.
			const newSelected = selectedRef.current
				?.map((prevEl) => {
					const matchedEntity = mergedData.find((el) => el.id === prevEl.id);
					// Remove if there's an existing application to any of the employers at a site
					// Otherwise, record the newer version of the entity in case the old selection had stale data
					return matchedEntity && !matchedEntity.processed
						? matchedEntity
						: null;
				})
				.filter((el): el is NonNullable<(typeof mergedData)[number]> => !!el);
			onChangeSelected(newSelected ?? []);
		} else {
			// The search results have changed, so we should reset the selection to []
			onChangeSelected([]);
		}
	}, [
		prevEntities,
		prevNearbyEntities,
		entities,
		nearbyEntities,
		selectedRef,
		onChangeSelected,
	]);
	const { page: rawPage } = useParams();
	const page = rawPage && !isNaN(parseInt(rawPage)) ? parseInt(rawPage) : 1;
	// If data is undefined, we are still loading results
	const disableIndex =
		data !== undefined &&
		!data.slice((page - 1) * PAGE_SIZE, page * PAGE_SIZE).length;
	// Either we're not ready to fetch, we haven't performed the initial fetch, or we are loading
	const showLoadingSpinner = !isReadyToFetch || !data || loading;

	return (
		<>
			{resolveHelmet?.(location?.label, PROFESSION2PROFVERBOSE[profession])}
			{disableIndex && (
				// Explicitly compare to 0 since `undefined` means we haven't fetched
				<Helmet>
					<meta name='robots' content='noindex' />
				</Helmet>
			)}
			<Box
				height='100%'
				position='relative'
				display='flex'
				flexDirection='column'
			>
				<Box
					flex='0 0'
					px={{
						xs: 1,
						md: 2,
					}}
					pb={{
						xs: 0,
						md: 2,
					}}
					pt={{
						xs: 1,
						md: 'initial',
					}}
					bgcolor={{
						md: 'light4.main',
					}}
					sx={{
						position: {
							xs: 'absolute',
							md: 'initial',
						},
						left: {
							xs: 0,
							md: 'initial',
						},
						top: {
							xs: 0,
							md: 'initial',
						},
						width: '100%',
						zIndex: {
							xs: 1,
							md: 'initial',
						},
					}}
				>
					<SearchBar
						disableLoadGmaps
						disabled={!mapRef}
						onClickFilters={handleClickFilters}
						filtersBadgeCount={Object.values(filtersCount).reduce(
							(tot, v) => tot + v,
							0,
						)}
						location={location}
						profession={profession}
						onSubmit={handleSubmitSearch}
						types={types}
						hideProfession={hideProfession}
						sx={{
							width: '100%',
							maxWidth: '900px',
							boxShadow: {
								xs: '0px 4px 5px -2px rgb(0 0 0 / 20%), 0px 7px 10px 1px rgb(0 0 0 / 14%), 0px 2px 16px 1px rgb(0 0 0 / 12%)',
								md: 'none',
							},
						}}
					/>
					<FiltersBar
						filters={filters}
						onClickAll={handleClickFilters}
						onApply={applyFilters}
						onDiscard={discardFilters}
						onChange={setRawFilters}
						value={rawFilters}
						filtersCount={filtersCount}
						sx={{
							mt: 2,
							display: {
								xs: 'none',
								md: 'flex',
							},
						}}
					/>
				</Box>
				<Box flex='1 1' display='flex'>
					<Box
						flex='1 1 50%'
						minWidth='540px'
						height='100%'
						display={{
							xs: 'none',
							md: 'block',
						}}
					>
						<DesktopSearchResults
							showAlts={showAlts}
							pageSize={PAGE_SIZE}
							open={!isLtMd}
							entities={entities}
							nearbyEntities={nearbyEntities}
							numNearbyRows={numNearbyRows}
							nearbyTitle={nearbyTitle}
							loading={showLoadingSpinner}
							onHover={handleHoverEnter}
							onLeave={handleHoverLeave}
							selectable={selectable}
							selected={selected}
							onSelect={handleSelect}
							region={region}
							slug={slug}
							baseRoute={baseRoute}
							pageRoute={pageRoute}
							profession={profession}
							pageTitle={resolveTitle?.(
								location?.label,
								PROFESSION2PROFVERBOSE[profession],
							)}
							pageTitleComponent={pageTitleComponent}
							badgeIcon={badgeIcon}
							badgeSxProps={badgeSxProps}
							sx={{
								height: '100%',
							}}
							dataTSResolver={dataTSResolver}
							slotId={slotId}
							highlightFeatured={highlightFeatured}
						/>
					</Box>
					<Box
						ref={setMapContainerRef}
						flex={{
							xs: '1 1 100%',
							md: '1 1 50%',
						}}
						height='100%'
						position='relative'
					>
						{initialized && !isCrawler && (
							<GoogleMapReact
								bootstrapURLKeys={BOOTSTRAP_URL_KEYS}
								center={center}
								zoom={zoom}
								yesIWantToUseGoogleMapApiInternals
								onChange={handleChange}
								onGoogleApiLoaded={handleMapLoaded}
								debounced={true}
								options={searchMapOptions(minZoom, maxZoom)}
							/>
						)}
						{!!clicked && (
							<Box
								sx={{
									display: 'flex',
									justifyContent: 'center',
									position: 'absolute',
									left: '8px',
									right: '8px',
									bottom: '8px',
									// Above SelectionFloater
									zIndex: 2,
									// Pass through to map
									pointerEvents: 'none',
								}}
							>
								<SearchPreview
									entityId={clicked}
									profession={profession}
									onClose={handleUnsetClick}
									badgeIcon={badgeIcon}
									badgeSxProps={badgeSxProps}
									sx={{
										pointerEvents: 'auto',
										width: '100%',
										maxWidth: '700px',
									}}
								/>
							</Box>
						)}
						{showStateLines && !!boundaryId && !disableBoundary && (
							<Button
								variant='contained'
								color='whiteButton'
								onClick={handleClickDisableBoundary}
								endIcon={<Close />}
								size={isLtMd ? 'small' : 'medium'}
								sx={{
									position: 'absolute',
									right: '8px',
									top: {
										xs: '76px',
										md: '8px',
									},
									zIndex: 1,
									border: `1px solid ${theme.palette.primary.main}`,
									color: 'primary.main',
									boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
								}}
							>
								Remove boundary
							</Button>
						)}
					</Box>
				</Box>
				{/* NOTE, marker for future use  */}
				{isLtMd && !clicked && (
					// Use conditional render b/c display 'none' css logic on the SwipeableDrawer results in buggy behavior
					// E.g. go to mobile view, select entity, then resize screen -> both preview and puller bar will dissaper
					//   b/c the transform: translateY of the SwipeableDrawer got messed up
					// Nesting the display 'none' css logic in an inner div doesn't help either b/c SwipeableDrawer will
					// disable interaction with the lower part of the screen still even when children not displayed
					<MobileSearchResults
						showAlts={showAlts}
						pageSize={PAGE_SIZE}
						open={expanded}
						loading={showLoadingSpinner}
						onOpen={() => handleExpanded(true)}
						onClose={() => handleExpanded(false)}
						onHover={handleHoverEnter}
						onLeave={handleHoverLeave}
						selectable={selectable}
						entities={entities}
						nearbyEntities={nearbyEntities}
						numNearbyRows={numNearbyRows}
						nearbyTitle={nearbyTitle}
						selected={selected}
						onSelect={handleSelect}
						SwipeAreaProps={{
							// Must always be 1 less than the presentation component
							sx: {
								zIndex: theme.zIndex.drawer - 3,
							},
						}}
						region={region}
						slug={slug}
						baseRoute={baseRoute}
						pageRoute={pageRoute}
						profession={profession}
						pageTitle={resolveTitle?.(
							location?.label,
							PROFESSION2PROFVERBOSE[profession],
						)}
						pageTitleComponent={pageTitleComponent}
						badgeIcon={badgeIcon}
						badgeSxProps={badgeSxProps}
						sx={{
							// Underneath mobile menu and SelectionFloater
							// (Note context stack is shared with mobile menu b/c fixed positioning + portal)
							zIndex: theme.zIndex.drawer - 2,
						}}
						dataTSResolver={dataTSResolver}
						slotId={slotId}
						highlightFeatured={highlightFeatured}
					/>
				)}
			</Box>
			{/* // create custom filters popup */}
			<FiltersPopup
				filters={filters}
				open={filtersOpen}
				value={rawFilters}
				onChange={setRawFilters}
				onClose={handleCloseFilters}
				onSubmit={handleApplyFilters}
				onReset={handleResetFilters}
			/>
			{/* // Facility map only // do we need to allow them to apply for jobs? */}
			<SearchInstructions
				open={!isCrawler && displayInstructions && showInstructions}
				onClose={handleCloseInstructions}
			/>
		</>
	);
};

export default BaseMap;
