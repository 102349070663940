import React, { useState, useCallback, useEffect } from 'react';

import { useMediaQuery, useTheme } from '@mui/material';

import FeaturedBadge from '@ivy/components/atoms/FeaturedBadge';
import RouteLink from '@ivy/components/atoms/RouteLink';
import {
	BriefcaseIcon,
	LocationIcon,
	RunningToWorkIcon,
} from '@ivy/components/icons';
import SelectionFloater from '@ivy/components/molecules/SelectionFloater';
import ApplyToJobPopup from '@ivy/components/organisms/ApplyToJobPopup';
import Map, { type MapWrapperProps } from '@ivy/components/organisms/Map';
import {
	type BaseMapItemObject,
	type NearbyBaseMapItemObject,
} from '@ivy/components/organisms/Map/BaseMap';
import { facilityFilters } from '@ivy/components/organisms/Map/filters/filterData';
import { type EntityFilterValues } from '@ivy/components/organisms/Map/filters/useEntityFilters';
import {
	Accreditation,
	accreditationShortLevels,
	accreditationShortNames,
	ED_VOLUME_RANGE,
	ED_VOLUME_VERBOSE,
	FACILITY_PLACEHOLDER_IMAGE,
} from '@ivy/constants/facility';
import { MILE_TO_METERS } from '@ivy/constants/location';
import { RouteUri } from '@ivy/constants/routes';
import { useCurrentAccount } from '@ivy/gql/hooks';
import { type FragmentType, getFragmentData, gql } from '@ivy/gql/types';
import {
	type Map_GetFacilityQuery,
	type Map_GeoSearchRankedFacilitiesQuery,
	type Map_FacilityFragment,
} from '@ivy/gql/types/graphql';
import { formatInteger } from '@ivy/lib/formatting/number';
import { buildInternalLink } from '@ivy/lib/util/route';
import { isCrawler } from '@ivy/lib/util/userAgent';

// Opt: Show nearby results they haven't applied to yet first
export const Map_GeoSearchRankedFacilitiesQDoc = gql(/* GraphQL */ `
	query Map_GeoSearchRankedFacilities(
		$xMin: float8!
		$xMax: float8!
		$yMin: float8!
		$yMax: float8!
		$placeId: String
		$state: String
		$filters: facility_bool_exp!
		$profession: profession!
		$includeNearby: Boolean!
	) {
		rankedFacilities: search_ranked_facilities_by_geography(
			args: {
				x_min: $xMin
				x_max: $xMax
				y_min: $yMin
				y_max: $yMax
				place_id: $placeId
				state: $state
				prof: $profession
			}
			where: { facility: $filters }
			order_by: [
				{ org_active: desc }
				{ max_norm_rate: desc_nulls_last }
				{ has_posting: desc }
				{ featured: desc }
				{ distance: asc }
			]
		) {
			id
			facility {
				id
				...Map_Facility
			}
		}
		nearbyFacilities: nearby_facilities_by_geography(
			args: {
				x_min: $xMin
				x_max: $xMax
				y_min: $yMin
				y_max: $yMax
				prof: $profession
				radius: 160934
			}
			where: { facility: $filters }
			order_by: [{ distance: asc }]
			limit: 50
		) @include(if: $includeNearby) {
			id
			...Map_NearbyFacility
		}
	}
`);

export const Map_NearbyFacilityFDoc = gql(/* GraphQL */ `
	fragment Map_NearbyFacility on nearby_facilities_by_geography_result {
		id
		distance
		facility {
			id
			name
			slug
			picture {
				id
				publicUrl: public_url
			}
			city
			state
			maxRatePosting: max_postings_for_prof(args: { prof: $profession }) {
				id
				slug
				title
				hasRate: has_rate
				ratePeriod: rate_period
				isExactRate: is_exact_rate
				exactRate: exact_rate
				minRate: min_rate
				maxRate: max_rate
				normRate: normalized_rate
				wageTrunc @client
			}
			numPostings: num_postings_for_prof(args: { prof: $profession })
			contracts(
				where: { active: { _eq: true } }
				order_by: { created_at: asc }
			) {
				id
				org {
					id
					name
					active
				}
				lastApplied: last_applied
				lastAppliedDate @client
				...ApplyToJobPopup_Contract
			}
		}
	}
`);

export const Map_FacilityQDoc = gql(/* GraphQL */ `
	query Map_GetFacility($id: uuid!, $profession: profession!) {
		facility: facility_by_pk(id: $id) {
			id
			...Map_Facility
		}
	}
`);

export const Map_FacilityFDoc = gql(/* GraphQL */ `
	fragment Map_Facility on facility {
		id
		name
		slug
		picture {
			id
			publicUrl: public_url
		}
		pedTraumaLvl: ped_trauma_lvl
		freestandingEr: freestanding_er
		facilityType @client
		residency
		location
		city
		state
		cmsFacilityPos: cms_facility_pos {
			id
			providerSubtype: provider_subtype
		}
		cmsFacilityEnrollment: cms_facility_enrollment {
			id
			providerTypeCode: provider_type_code
		}
		cmsFacility: cms_facility {
			id
			hospitalType: hospital_type
			metrics(where: { measure_id: { _eq: "EDV" } }) {
				id
				measureId: measure_id
				score
			}
			facilities(order_by: [{ name: asc }]) {
				id
				name
			}
		}
		ahaFacility: aha_facility_22 {
			id: ID
			svc1 {
				id: ID
				adultTraumaLvl: TRAUML90
			}
			units {
				id: ID
				name: UNAME
				par: parent_aha_facility {
					id: ID
					name: MNAME
				}
			}
			rollups @client
		}
		maxRatePosting: max_postings_for_prof(args: { prof: $profession }) {
			id
			slug
			title
			hasRate: has_rate
			ratePeriod: rate_period
			isExactRate: is_exact_rate
			exactRate: exact_rate
			minRate: min_rate
			maxRate: max_rate
			normRate: normalized_rate
			wageTrunc @client
		}
		numPostings: num_postings_for_prof(args: { prof: $profession })
		featured: featured_for_prof(args: { prof: $profession })
		immediateNeeds(args: { prof: $profession }) @client
		contracts(where: { active: { _eq: true } }, order_by: { created_at: asc }) {
			id
			org {
				id
				name
				active
			}
			lastApplied: last_applied
			lastAppliedDate @client
			...ApplyToJobPopup_Contract
		}
		reviewAgg: reviews_aggregate(
			where: {
				active: { _eq: true }
				visible: { _eq: true }
				has_active_contract: { _eq: true }
			}
		) {
			aggregate {
				avg {
					rating: rating_job
				}
				count
			}
		}
		accreditations {
			id
			accreditation
			level
		}
	}
`);

export interface FacilityMapItemObject extends BaseMapItemObject {
	contracts: Map_FacilityFragment['contracts'];
}

export interface FacilityNearbyItemObject extends NearbyBaseMapItemObject {
	contracts: Map_FacilityFragment['contracts'];
}

const resolver = (
	rawData: FragmentType<typeof Map_FacilityFDoc>,
	appliedFilters: EntityFilterValues,
): FacilityMapItemObject => {
	const facility = getFragmentData(Map_FacilityFDoc, rawData);

	let newObject: FacilityMapItemObject = {
		id: facility.id,
		disabled: false,
		location: null,
		picture: FACILITY_PLACEHOLDER_IMAGE,
		stats: [],
		contracts: [],
	};

	const featuredPosting = facility.maxRatePosting![0];

	newObject.stats = [
		{
			label: 'Facility Type:',
			value: facility.facilityType,
		},
	];

	// Opt: Add in filter handlers for all applied filters
	if (
		Array.isArray(appliedFilters.accreditations) &&
		appliedFilters.accreditations.length
	) {
		newObject.stats.push({
			label: 'ACEP Accreditation:',
			value:
				facility.accreditations
					.slice()
					.sort(
						(a, b) =>
							(Object.values(Accreditation) as string[]).indexOf(
								a.accreditation,
							) -
							(Object.values(Accreditation) as string[]).indexOf(
								b.accreditation,
							),
					)
					.map(
						(el) =>
							accreditationShortNames[el.accreditation] +
							(el.level
								? ` (${
										accreditationShortLevels[el.accreditation]?.[el.level] ??
										el.level
								  })`
								: ''),
					)
					.join(', ') || 'None',
		});
	} else {
		newObject.stats.push({
			label: 'EM Residency:',
			value: facility.residency || 'None',
		});
	}

	const edv = facility.cmsFacility?.metrics.find((el) => el.measureId === 'EDV')
		?.score;
	const numEntities =
		(facility.cmsFacility?.facilities.length ?? 0) > 1
			? ` across ${formatInteger(
					facility.cmsFacility!.facilities.length,
			  )} sites`
			: '';
	if (edv) {
		newObject.stats.push({
			label: 'Annual ED Visits:',
			value: `${ED_VOLUME_VERBOSE[edv]} (${ED_VOLUME_RANGE[edv]})${numEntities}`,
		});
	}

	if (featuredPosting) {
		newObject.stats.push({
			label: 'Job Posting:',
			value: featuredPosting.title,
			isLink: true,
			linkProps: {
				to: {
					pathname: buildInternalLink(RouteLink.routes.JOB_POSTING_SHOW, {
						postingId: [featuredPosting.id, featuredPosting.slug],
					}),
					whitelabel: 'default',
				},
				state: {
					backNav: {
						target: 'search',
					},
				},
				openInNewTab: true,
			},
			chipLabel:
				facility.numPostings && facility.numPostings > 1
					? `+${facility.numPostings - 1}`
					: undefined,
		});
	}

	const facPathname = buildInternalLink(RouteLink.routes.FACILITY_SHOW, {
		facilityId: [facility.id, facility.slug],
	});

	newObject = {
		...newObject,
		title: facility.name,
		location: facility.location,
		locationInfo: `${facility.city}, ${facility.state}`,
		picture: facility.picture?.publicUrl || FACILITY_PLACEHOLDER_IMAGE,
		businessInfo: facility.contracts.map((el) => el.org.name).join(', '),
		disabled: facility.contracts.every((el) => !el.org.active),
		rating:
			(facility!.reviewAgg!.aggregate!.avg?.rating || 0) > 0
				? facility.reviewAgg.aggregate?.avg?.rating?.toFixed(1)
				: undefined,
		badgeText: facility.immediateNeeds ? 'Immediate Needs' : undefined,
		badgeRate: featuredPosting?.hasRate ? featuredPosting.wageTrunc : undefined,
		processed: facility.contracts.some((contract) => contract.lastAppliedDate),
		contracts: facility.contracts,
		featured: facility.immediateNeeds || undefined,
		pathname: facPathname,
		linkProps: {
			to: {
				pathname: facPathname,
			},
			state: {
				backNav: {
					target: 'search',
				},
			},
		},
	};

	return newObject;
};

const handleResolver = (
	data: Map_GeoSearchRankedFacilitiesQuery | undefined,
	appliedFilters: EntityFilterValues,
): FacilityMapItemObject[] | undefined =>
	data
		? data.rankedFacilities
				.map((el) => el.facility)
				.filter(
					(
						el,
					): el is NonNullable<
						Map_GeoSearchRankedFacilitiesQuery['rankedFacilities'][0]['facility']
					> => !!el,
				)
				.map((facility) => resolver(facility, appliedFilters))
		: undefined;

const handlePreviewResolver = (
	data: Map_GetFacilityQuery | undefined,
	appliedFilters: EntityFilterValues,
) => (data?.facility ? resolver(data?.facility, appliedFilters) : undefined);

const nearbyResolver = (
	rawData: FragmentType<typeof Map_NearbyFacilityFDoc>,
): FacilityNearbyItemObject | null => {
	const nearbyFacility = getFragmentData(Map_NearbyFacilityFDoc, rawData);

	if (
		!nearbyFacility.facility ||
		!nearbyFacility.facility.maxRatePosting?.length
	) {
		return null;
	}

	return {
		badge: nearbyFacility.facility.maxRatePosting[0].hasRate ? (
			<FeaturedBadge
				label={nearbyFacility.facility.maxRatePosting[0].wageTrunc}
			/>
		) : undefined,
		distance: nearbyFacility.distance,
		id: nearbyFacility.id,
		picture:
			nearbyFacility.facility.picture?.publicUrl || FACILITY_PLACEHOLDER_IMAGE,
		title: nearbyFacility.facility.name,
		titleTo: buildInternalLink(RouteUri.FACILITY_SHOW, {
			facilityId: [nearbyFacility.facility.id, nearbyFacility.facility.slug],
		}),
		subtitle: nearbyFacility.facility.maxRatePosting[0].title,
		subtitleTo: buildInternalLink(RouteUri.JOB_POSTING_SHOW, {
			postingId: [
				nearbyFacility.facility.maxRatePosting[0].id,
				nearbyFacility.facility.maxRatePosting[0].slug,
			],
		}),
		subtitleChipLabel:
			nearbyFacility.facility.numPostings &&
			nearbyFacility.facility.numPostings > 1
				? `+${formatInteger(nearbyFacility.facility.numPostings - 1)}`
				: undefined,
		captions: [
			{
				icon: (
					<LocationIcon
						sx={{
							fontSize: '16px',
							position: 'relative',
							top: '3px',
							mr: '2px',
						}}
					/>
				),
				label: `${nearbyFacility.facility.city}, ${
					nearbyFacility.facility.state
				} (${formatInteger(
					Math.ceil(nearbyFacility.distance / MILE_TO_METERS),
				)} mi)`,
			},
			{
				icon: (
					<BriefcaseIcon
						sx={{
							fontSize: '16px',
							position: 'relative',
							top: '3px',
							mr: '4px',
						}}
					/>
				),
				label: nearbyFacility.facility.contracts
					.map((el) => el.org.name)
					.join(', '),
			},
		],
		contracts: nearbyFacility.facility.contracts,
		processed: nearbyFacility.facility.contracts.some(
			(contract) => contract.lastAppliedDate,
		),
	};
};

const handleNearbyResolver = (
	data: Map_GeoSearchRankedFacilitiesQuery | undefined,
): FacilityNearbyItemObject[] | undefined =>
	data
		? data.nearbyFacilities
				?.map((el) => nearbyResolver(el))
				.filter((el): el is NonNullable<typeof el> => !!el)
		: undefined;

export type FacilityMapProps = Omit<
	MapWrapperProps<
		Map_GeoSearchRankedFacilitiesQuery,
		Map_GetFacilityQuery,
		FacilityMapItemObject,
		FacilityNearbyItemObject
	>,
	| 'entityType'
	| 'onSelect'
	| 'selectable'
	| 'selected'
	| 'onExpanded'
	| 'queryDoc'
	| 'queryPreviewDoc'
	| 'filters'
	| 'dataResolver'
	| 'dataNearbyResolver'
	| 'dataPreviewResolver'
	| 'resolver'
	| 'nearbyResolver'
	| 'previewResolver'
>;

const FacilitySearch = ({ ...props }: FacilityMapProps) => {
	const theme = useTheme();
	const currAcc = useCurrentAccount();
	const isLtMd = useMediaQuery(theme.breakpoints.down('md'), { noSsr: true });
	const [expanded, setExpanded] = useState(isCrawler);
	const [selected, setSelected] = useState<
		(FacilityMapItemObject | FacilityNearbyItemObject)[]
	>([]);
	const [invPopupOpen, setInvPopupOpen] = useState(false);
	const selectable = !currAcc || currAcc.isClinician;

	const handleClickReset = useCallback(() => {
		setSelected([]);
	}, [setSelected]);

	const handleClickApply = useCallback(() => {
		setInvPopupOpen(true);
	}, [setInvPopupOpen]);

	const handleCloseInvPopup = useCallback(() => {
		setInvPopupOpen(false);
	}, [setInvPopupOpen]);

	const handleCompleteInvPopup = useCallback(() => {
		// Close popup since we won't be able to pass in the selected contracts as a prop once
		// we clear them
		setInvPopupOpen(false);
		setSelected([]);
	}, [setInvPopupOpen, setSelected]);

	useEffect(() => {
		// This scenario can happen where an anonymous clinician selects a entity they're already connected with and
		// then logs in. Then the # of contracts will be 0, so we should close this popup
		// could be moved to parent on select functionality
		if (!selected.length) {
			setInvPopupOpen(false);
		}
	}, [selected, setInvPopupOpen]);

	useEffect(() => {
		// If log in as a non-clinician, clear selection
		if (!selectable) {
			setSelected([]);
		}
	}, [selectable, setSelected]);

	const handleChangeSelected = useCallback(
		(nv: typeof selected) => {
			setSelected(nv);
		},
		[setSelected],
	);

	return (
		<>
			<Map
				entityType='FACILITY'
				queryDoc={Map_GeoSearchRankedFacilitiesQDoc}
				queryPreviewDoc={Map_FacilityQDoc}
				filters={facilityFilters}
				resolver={handleResolver}
				nearbyResolver={handleNearbyResolver}
				nearbyTitle='Nearby immediate needs'
				numNearbyRows={2}
				previewResolver={handlePreviewResolver}
				selectable={selectable}
				selected={selected}
				onChangeSelected={handleChangeSelected}
				onExpanded={setExpanded}
				badgeIcon={<RunningToWorkIcon style={{ fontSize: '1em' }} />}
				dataTSResolver={(prof, id) => `${id}-EM-${prof}`}
				slotId='facility-search'
				highlightFeatured
				{...props}
			/>
			{selectable && (!isLtMd || expanded) && (
				<SelectionFloater
					minimized={isLtMd}
					selected={selected}
					onClickReset={handleClickReset}
					onClick={handleClickApply}
					options={[
						{
							title: isLtMd
								? 'Apply'
								: `Apply to EM ${
										selected.length > 1 ? 'practices' : 'practice'
								  }`,
							value: 'apply',
						},
					]}
					sx={{
						position: 'absolute',
						bottom: 24,
						left: '50%',
						transform: 'translateX(-50%)',
						// Beneath SearchPreview on desktop, above MobileSearchResults on mobile but less than
						// mobile menu.
						zIndex: isLtMd ? theme.zIndex.drawer - 1 : 1,
					}}
				/>
			)}
			{invPopupOpen && !!selected.map((el) => el.contracts).flat().length && (
				<ApplyToJobPopup
					open
					// Don't have to worry about filtering out contracts from selected entities b/c can only select
					// entities with which you have not connected to ANYONE there, so all contracts there will be
					// available to connect with
					contracts={selected.map((el) => el.contracts).flat()}
					onClose={handleCloseInvPopup}
					onComplete={handleCompleteInvPopup}
					refetchQueries={['Map_GeoSearchRankedFacilities']}
				/>
			)}
		</>
	);
};

export default FacilitySearch;
