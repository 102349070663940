import React from 'react';

import {
	Box,
	Divider,
	Stack,
	Typography,
	Switch,
	Slider,
	Chip,
} from '@mui/material';
import * as yup from 'yup';

import { BriefcaseIcon } from '@ivy/components/icons';
import CurrentPlan from '@ivy/components/organisms/CurrentPlan';
import { formatInteger } from '@ivy/lib/formatting/number';
import { type FormStepProps } from '@ivy/lib/forms/formFormatHelpers';
import { getErrorState } from '@ivy/lib/forms/formikHelpers';
import {
	resolveJobPostingPrice,
	resolveBasePrice,
	JobPostPriceInterval,
	calculateDiscountAmount,
	calculateDiscountedPrice,
	calculateDiscountRate,
	getJobPostValRep,
	getSliderValue,
	CheckoutModes,
	billingSliderMarks,
} from '@ivy/lib/services/billing';

import { type CheckoutFormValues, type ExtraParams } from '../CheckoutForm';

interface PlanStepValues {
	slots: number;
	interval: JobPostPriceInterval;
}

const validation = yup.object({
	slots: yup.number().required(),
	interval: yup.string().oneOf(Object.values(JobPostPriceInterval)).required(),
});

const initialValues = {
	slots: 2,
	interval: JobPostPriceInterval.MONTHLY,
};

const PlanStep = ({
	formik,
	extra,
}: FormStepProps<CheckoutFormValues, ExtraParams>) => {
	const { values, setFieldValue } = formik;
	const yearly = values.interval === JobPostPriceInterval.YEARLY;
	const sliderValue = getSliderValue(values.slots);

	const handleSliderChange = (val: number) => {
		setFieldValue('slots', getJobPostValRep(val));
	};

	const handleIntervalChange = (val: boolean) => {
		const newInterval = val
			? JobPostPriceInterval.YEARLY
			: JobPostPriceInterval.MONTHLY;
		setFieldValue('interval', newInterval);
	};

	const enterpriseQuote =
		sliderValue > billingSliderMarks.length - 1 &&
		extra.mode !== CheckoutModes.PROCESS;
	const processLock = extra.mode === CheckoutModes.PROCESS;
	const interval = yearly
		? JobPostPriceInterval.YEARLY
		: JobPostPriceInterval.MONTHLY;
	const price = resolveJobPostingPrice(extra.jobPostPrices, interval);
	const basePrice = resolveBasePrice(extra.jobPostPrices);
	const discountRate =
		price && basePrice
			? calculateDiscountRate(
					price,
					basePrice,
					getJobPostValRep(sliderValue),
					values.interval,
			  )
			: 0;

	const renderPriceDisplay = () => {
		if (enterpriseQuote) {
			return (
				<Typography variant='h3' sx={{ display: 'flex', alignItems: 'center' }}>
					Schedule a meeting for a custom quote
				</Typography>
			);
		}

		const displayDifference =
			sliderValue !== 1 || interval !== JobPostPriceInterval.MONTHLY;

		if (price && basePrice) {
			return (
				<Typography
					component='div'
					variant='h3'
					sx={{ display: 'flex', alignItems: 'center' }}
				>
					{displayDifference ? (
						<Typography
							component='div'
							variant='body1'
							color='text.icon'
							sx={{
								mr: 1,
								textDecoration: 'line-through',
							}}
						>
							${basePrice.amount}
						</Typography>
					) : null}
					$
					{formatInteger(
						calculateDiscountedPrice(
							price,
							getJobPostValRep(sliderValue),
							interval,
						),
					)}
					<Typography
						component='div'
						variant='body1'
						color='text.icon'
						sx={{
							ml: 1,
						}}
					>
						per job posting slot
						{displayDifference ? (
							<Chip
								label={`-${discountRate}%`}
								sx={{
									height: 'auto',
									fontSize: '12px',
									fontWeight: 'bold',
									bgcolor: 'rgba(30, 200, 106, 0.20)',
									color: 'text.icon',
									ml: 2,
									mb: 'auto',
									mt: '-15px',
									span: {
										px: 1,
										py: 0.25,
									},
								}}
							/>
						) : null}
					</Typography>
				</Typography>
			);
		}
	};

	const slotsErrorState = getErrorState(formik, 'slots');
	const processLockTitle =
		sliderValue > 50
			? 'Enterprise'
			: sliderValue === 1
			? 'Starter'
			: 'Professional';

	return (
		<Stack spacing={8}>
			{extra.currSubscription || extra.legacyJobPostingCount ? (
				<Box>
					<CurrentPlan
						slots={extra.currSubscription?.slots}
						interval={extra.currSubscription?.interval}
						subtotal={extra.currSubscription?.invoice?.total}
						status={extra.currSubscription?.status}
						legacyCount={
							!extra.currSubscription ? extra.legacyJobPostingCount : undefined
						}
						hideTotal={extra.mode === CheckoutModes.PAYMENT}
					/>
					<Divider sx={{ mt: 3 }} />
				</Box>
			) : undefined}
			<Stack direction='column' spacing={3}>
				<Typography variant='h2'>
					{processLock ? `${processLockTitle} plan` : 'Select your plan'}
				</Typography>
				<Typography variant='body1'>
					{processLock
						? `Your custom plan is set with the billing interval and number of slots you've chosen. Please proceed to the next step to complete your checkout.`
						: `Use the slider to select how many job posting slots you need per ${
								yearly ? 'year' : 'month'
						  }`}
				</Typography>
				<Stack
					direction='row'
					spacing={1}
					sx={{
						display: processLock ? 'none' : 'flex',
						alignItems: 'center',
					}}
				>
					<Typography variant='body1' fontWeight='bold'>
						Monthly
					</Typography>
					<Switch
						checked={yearly}
						color='secondary'
						disabled={processLock}
						onChange={() => handleIntervalChange(!yearly)}
					/>
					<Typography variant='body1' fontWeight='bold'>
						Yearly -10%
					</Typography>
				</Stack>
			</Stack>
			<Stack sx={{ pt: 2 }}>
				<Box>
					{renderPriceDisplay()}
					<Typography
						variant='body1'
						fontWeight='bold'
						sx={{ display: 'flex', alignItems: 'center', mt: 1 }}
					>
						<BriefcaseIcon sx={{ fontSize: 'inherit', mb: '-1px', mr: 0.5 }} />{' '}
						{enterpriseQuote
							? `51+ job posting slots / ${interval.toLowerCase()}`
							: `${getJobPostValRep(sliderValue)} ${yearly ? 'monthly' : ''} ${
									sliderValue === 1 ? 'slot' : 'slots'
							  } / Total $${
									price
										? formatInteger(
												calculateDiscountAmount(
													price,
													getJobPostValRep(sliderValue),
													interval,
												),
										  )
										: 0
							  } ${interval.toLowerCase()}`}
					</Typography>
					<Stack
						display='flex'
						direction='row'
						spacing={2}
						sx={{ alignItems: 'center', justifyContent: 'center' }}
					>
						<Slider
							onChange={(evn, val) => {
								if (!Array.isArray(val)) handleSliderChange(val);
							}}
							value={sliderValue}
							min={1}
							max={billingSliderMarks.length}
							marks={billingSliderMarks}
							disabled={processLock}
							step={1}
							color='secondary'
							sx={{ mt: 3, height: '16px' }}
						/>
					</Stack>
					{!!slotsErrorState.error && (
						<Typography variant='caption' color='error.main' sx={{ mt: 4 }}>
							{slotsErrorState.helperText}
						</Typography>
					)}
				</Box>
			</Stack>
		</Stack>
	);
};

export default PlanStep;
export { type PlanStepValues, validation, initialValues };
