import React, { useEffect, useState, useMemo } from 'react';

import { useQuery, useReactiveVar, useSubscription } from '@apollo/client';
import jwtDecode from 'jwt-decode';
import { useSearchParams } from 'react-router-dom';

import config from '@ivy/config';
import { AccountType } from '@ivy/constants/account';
import { getNewToken } from '@ivy/gql/client/jwt';
import {
	isAuthenticatedVar,
	currentAccountBaseVar,
	currentAccountRolesVar,
} from '@ivy/gql/reactives';
import { gql } from '@ivy/gql/types';
import { useAcqParams } from '@ivy/lib/hooks';
import { storeInvitee, clearInvitee } from '@ivy/lib/storage/invitee';
import {
	getAccessToken,
	getRefreshToken,
	ACCESS_TOKEN_IDENTIFIER,
	logOut,
	removeAccessToken,
	removeRefreshToken,
	storeRefreshToken,
} from '@ivy/lib/storage/token';

import AuthContext from './AuthContext';

const AuthProvider_CurrentAccountRoleSDoc = gql(/* GraphQL */ `
	subscription AuthProvider_CurrentAccountRoles {
		currentAccount: current_account {
			roles {
				id
				role
			}
		}
	}
`);

const AuthProvider_CurrentAccountQDoc = gql(/* GraphQL */ `
	query AuthProvider_CurrentAccount(
		$isClinician: Boolean!
		$isOrgUser: Boolean!
	) {
		currentAccount: current_account {
			id
			accessSalary: access_salary
			ci: contact_info {
				id
				email
				confirmedEmail: confirmed_email
			}
			pi: personal_info {
				id
				firstName: first_name
				lastName: last_name
				fullName: full_name
			}
			...CurrentAccountAvatar_CurrentAccount
			type
			picture {
				id
				publicUrl: public_url
			}
			orgUser: org_user @include(if: $isOrgUser) {
				id
				org {
					id
					name
					slug
					verified
				}
			}
			clinician @include(if: $isClinician) {
				id
				fid
				profileComplete: profile_complete
				account {
					id
					pi: personal_info {
						id
						firstName: first_name
						lastName: last_name
						fullName: full_name
					}
				}
				reportedSalary: reported_salary
				profDegree: prof_degree
				profession
				creds
				isAPP @client
			}
			isClinician @client
			isOrgUser @client
			superuser
			hideSearchInstructions: hide_search_instructions
			abTests: ab_tests {
				id
				test
				group
			}
		}
	}
`);

const determineJwtClinician = (accessToken: null | string | undefined) => {
	if (!accessToken) {
		return false;
	}
	return (
		(jwtDecode(accessToken) as { ivy: { 'x-account-type': string } })['ivy'][
			'x-account-type'
		] === AccountType.CLINICIAN
	);
};

const determineJwtOrgUser = (accessToken: null | string | undefined) => {
	if (!accessToken) {
		return false;
	}
	return (
		(jwtDecode(accessToken) as { ivy: { 'x-account-type': string } })['ivy'][
			'x-account-type'
		] === AccountType.ORG
	);
};

export interface AuthProviderProps {
	children?: React.ReactNode;
}

const AuthProvider = ({ children }: AuthProviderProps) => {
	const [searchParams, setSearchParams] = useSearchParams();
	const [currRoles, setCurrRoles] = useState<string[] | null>(null);
	const isAuthenticated = useReactiveVar(isAuthenticatedVar);
	const at = getAccessToken();
	const [isClinician, isOrgUser] = useMemo(() => {
		return [determineJwtClinician(at), determineJwtOrgUser(at)];
	}, [at]);
	const { data, loading, error } = useQuery(AuthProvider_CurrentAccountQDoc, {
		skip: !isAuthenticated,
		fetchPolicy: 'network-only',
		variables: {
			isClinician: isClinician,
			isOrgUser: isOrgUser,
		},
	});
	const {
		data: roleData,
		loading: roleLoading,
		error: roleError,
	} = useSubscription(AuthProvider_CurrentAccountRoleSDoc, {
		skip: !isAuthenticated,
		fetchPolicy: 'network-only',
	});
	const [initialized, setInitialized] = useState(false);
	useAcqParams();

	useEffect(() => {
		/**
		 * Checks the user's role and updates the current roles state accordingly.
		 * If the user's roles have changed, it also triggers a function to get a new token.
		 */
		const roleCheck = async () => {
			if (!roleData?.currentAccount?.length) {
				return;
			}

			// Access roles
			const currAccRoles = roleData.currentAccount[0];
			currentAccountRolesVar(currAccRoles);

			if (currRoles === null) {
				setCurrRoles(currAccRoles.roles.map((role) => role.role));
			} else if (currRoles.length !== currAccRoles.roles.length) {
				setCurrRoles(currAccRoles.roles.map((role) => role.role));
				await getNewToken();
			}
		};
		roleCheck();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [roleData]);

	useEffect(() => {
		const runInit = async () => {
			let changedSearchParams = false;

			const inviteEmail = searchParams.get('inviteEmail');
			const inviteOrgId = searchParams.get('inviteOrgId');
			if (inviteEmail && inviteOrgId) {
				storeInvitee({ inviteEmail, inviteOrgId });
				searchParams.delete('inviteEmail');
				searchParams.delete('inviteOrgId');
				changedSearchParams = true;
			}

			const emailTr = searchParams.get('emailTr');
			if (emailTr) {
				// This link will record the user agent and IP address
				fetch(`${config.apiUrl}/v1/email/px/${encodeURIComponent(emailTr)}`);
				searchParams.delete('emailTr');
				changedSearchParams = true;
			}

			const refreshTokenParam = searchParams.get('refreshToken');
			if (refreshTokenParam) {
				searchParams.delete('refreshToken');
				changedSearchParams = true;
				// Store the refresh token and get an access token
				storeRefreshToken(refreshTokenParam);
				await getNewToken();
			}

			if (changedSearchParams) {
				// Restore the other search params, only deleting these two
				setSearchParams(searchParams, {
					replace: true,
				});
			}

			const accessToken = getAccessToken();
			const refreshToken = getRefreshToken();
			if (accessToken && refreshToken) {
				isAuthenticatedVar(true);
			} else {
				// Prevent weird edge cases where one exists but not the other
				removeAccessToken();
				removeRefreshToken();
				setInitialized(true);
			}
		};
		runInit();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (!data) {
			return;
		}
		// Login
		if (data.currentAccount.length) {
			const currAcc = data.currentAccount[0];
			currentAccountBaseVar(currAcc);
			clearInvitee();
			setInitialized(true);
		} else {
			// User account deleted and access token has not expired yet.
			// Then need to remove tokens and set isAuthenticatedVar to false.
			// Easiest just to reset state and call `logOut`.
			logOut();
		}
	}, [data]);

	useEffect(() => {
		if (!initialized) {
			return;
		}

		const detectAuthChange = (event) => {
			// https://developer.mozilla.org/en-US/docs/Web/API/Window/storage_event
			if (
				isAuthenticated &&
				(event.key === null ||
					(event.key === ACCESS_TOKEN_IDENTIFIER && event.newValue === null))
			) {
				// null event.key means local storaged was cleared
				// null event.newValue means item was deleted from local storage, also possible through a clear
				// Tokens no longer available -> logged out on a different tab
				// Note that tokens are stored before isAuthenticatedVar is set, so we won't have the diabolical edge case
				// where a user signs up and isAuthenticatedVar is true but the tokens haven't been set in storage yet
				console.log('Logout detected on separate tab');
				logOut();
			} else if (
				!isAuthenticated &&
				event.key === ACCESS_TOKEN_IDENTIFIER &&
				event.newValue !== null
			) {
				// Tokens became available -> logged in on a different tab
				// Note that our convention is the refresh token is set before the access token, so the refresh
				// token is guaranteed to be available by this point
				console.log('Login detected on separate tab');
				isAuthenticatedVar(true);
			}
		};

		window.addEventListener('storage', detectAuthChange);
		return () => window.removeEventListener('storage', detectAuthChange);
	}, [initialized, isAuthenticated]);

	const authProviderValue = useMemo(() => {
		return {
			initialized,
			loading: loading || roleLoading,
			error: error || roleError,
		};
	}, [initialized, loading, error, roleLoading, roleError]);

	return (
		<AuthContext.Provider value={authProviderValue}>
			{children}
		</AuthContext.Provider>
	);
};

export default AuthProvider;
