import React, {
	memo,
	Suspense,
	useCallback,
	useEffect,
	useMemo,
	useState,
} from 'react';

import { type ApolloError, useMutation } from '@apollo/client';
import { useQuery } from '@apollo/client';
import {
	Box,
	type SxProps,
	type Theme,
	Stack,
	useMediaQuery,
	useTheme,
	Skeleton,
	Grid,
} from '@mui/material';
import { type GridSortModel } from '@mui/x-data-grid-premium';
import { captureException } from '@sentry/react';
import { format as formatDate } from 'date-fns';
import { useSnackbar } from 'notistack';

import RouteLink from '@ivy/components/atoms/RouteLink';
import SelectionFloater from '@ivy/components/molecules/SelectionFloater';
import ConfirmationDialog from '@ivy/components/organisms/ConfirmationDialog';
import { useRedirect } from '@ivy/components/providers/RedirectProvider';
import {
	generateNewExpirationDate,
	JOB_POSTING_EXPIRING_SOON_WINDOW_DAYS,
} from '@ivy/constants/posting';
import { gql } from '@ivy/gql/types';
import {
	type JobPostList_SearchJobPostsQuery,
	type JobPostList_SearchJobPostsQueryVariables,
} from '@ivy/gql/types/graphql';
import { displayErrorMessageToast } from '@ivy/lib/helpers/error';
import { useNotifyError } from '@ivy/lib/hooks';
import { combineSx } from '@ivy/lib/styling/sx';
import lazyRetry from '@ivy/lib/util/lazyRetry';
import { buildInternalLink } from '@ivy/lib/util/route';
import { dateFromNow } from '@ivy/lib/validation/date';

import { JobPostsTabs } from '../JobPosting';

import { FilterBar, FiltersPopup, useFilterContext } from './filters';
import { type JobPostTableProps } from './JobPostingTable';

// Since we have multiple lazyRetry imports, we need to pass a unique key to each
const jGrid = lazyRetry(
	() => import('./JobPostingGrid'),
	'JobPostList_JobPostGrid',
);
const jTable = lazyRetry(
	() => import('./JobPostingTable'),
	'JobPostList_JobPostTable',
);

const DEFAULT_PAGE_SIZE = {
	// 10 rows of 1 card
	xs: 3,
	// 10 rows of 2 cards
	sm: 10 * 2,
	// 10 rows of 3 cards
	md: 10 * 3,
	desktop: 25,
};

// We're including inactive contracts in filters + connections
export const JobPostList_SearchJobPostsQDoc = gql(/* GraphQL */ `
	query JobPostList_SearchJobPosts(
		$limit: Int!
		$offset: Int!
		$orderBy: [relevant_job_posting_order_by!]
		$search: String!
		$filters: relevant_job_posting_bool_exp!
		$dateInPast: timestamp!
		$orgId: uuid!
		$expirationDate: date!
	) {
		relevantJobPosts: search_relevant_job_posting(
			where: $filters
			limit: $limit
			offset: $offset
			order_by: $orderBy
			args: { search: $search }
		) {
			id
			jobPost: job_posting {
				id
				...JobPostCard_JobPostInfo
				...JobPostTable_JobPostInfo
			}
		}
		agg: search_relevant_job_posting_aggregate(
			where: $filters
			args: { search: $search }
		) {
			aggregate {
				count
			}
		}
		aggActive: search_relevant_job_posting_aggregate(
			where: {
				job_posting: {
					_and: [
						{ active: { _eq: true }, contract: { org_id: { _eq: $orgId } } }
					]
				}
			}
			args: { search: "" }
		) {
			aggregate {
				count
			}
		}
		aggExpiringSoon: search_relevant_job_posting_aggregate(
			where: {
				job_posting: {
					_and: [
						{
							active: { _eq: true }
							expiration_date: { _lte: $expirationDate }
							contract: { org_id: { _eq: $orgId } }
						}
					]
				}
			}
			args: { search: "" }
		) {
			aggregate {
				count
			}
		}
		aggInactive: search_relevant_job_posting_aggregate(
			where: {
				job_posting: {
					_and: [
						{
							active: { _eq: false }
							latest_publication: { unpublished_at: { _gte: $dateInPast } }
							contract: { org_id: { _eq: $orgId } }
						}
					]
				}
			}
			args: { search: "" }
		) {
			aggregate {
				count
			}
		}
	}
`);

const JobPostList_BulkUpdateJobPostMDoc = gql(/* GraphQL */ `
	mutation JobPostList_BulkUpdateJobPost(
		$jobPostingIds: [uuid!]!
		$active: Boolean!
		$expirationDate: date
	) {
		update_job_posting(
			where: { id: { _in: $jobPostingIds } }
			_set: {
				active: $active
				last_modified: "now()"
				expiration_date: $expirationDate
			}
		) {
			returning {
				id
			}
		}
	}
`);

const DEFAULT_ORDER_BY = [
	{
		job_posting: {
			latest_publication: { published_at: 'desc' },
		},
	},
	{
		job_posting: {
			id: 'asc',
		},
	},
];

interface JobPostListProps {
	filtersPopupOpen?: boolean;
	setFiltersPopupOpen?: (newValue: boolean) => void;
	onCompletedQuery?: (val?: JobPostList_SearchJobPostsQuery) => void;
	onErrorQuery?: (val?: ApolloError) => void;
	searchTerm?: string;
	sx?: SxProps<Theme>;
	refetchQueries?: string[];
	where?: JobPostList_SearchJobPostsQueryVariables['filters'];
	orgId: string;
	tableProps?: Omit<
		JobPostTableProps,
		| 'onChangeSort'
		| 'jobPosts'
		| 'loading'
		| 'filtering'
		| 'onEdit'
		| 'onPublish'
		| 'onUnpublish'
	>;
	selectable?: boolean;
	tabValue?: JobPostsTabs;
}

const JobPostTable = memo(jTable);
const JobPostGrid = memo(jGrid);

interface SkeletonProps {
	size?: number;
	sx?: SxProps<Theme>;
}

export const TableSkeleton = ({ size = 0, sx }: SkeletonProps) => (
	<Grid
		sx={combineSx({ overflow: 'hidden' }, sx)}
		container
		wrap='nowrap'
		direction='column'
		spacing={2}
	>
		<Grid item xs={12} pb={2}>
			<Skeleton variant='rectangular' height={93} />
		</Grid>

		{[...Array(size).keys()].map((el) => (
			<Grid key={el} container item xs={12} spacing={2}>
				<Grid item xs={1} py={3}>
					<Skeleton variant='rectangular' height={30} />
				</Grid>
				<Grid item xs={4} py={3}>
					<Skeleton variant='rectangular' height={30} />
				</Grid>
				<Grid item xs={2} py={3}>
					<Skeleton variant='rectangular' height={30} />
				</Grid>
				<Grid item xs={3} py={3}>
					<Skeleton variant='rectangular' height={30} />
				</Grid>
				<Grid item xs={2} py={3}>
					<Skeleton variant='rectangular' height={30} />
				</Grid>
			</Grid>
		))}
	</Grid>
);

export const GridSkeleton = ({ sx, size = 0 }: SkeletonProps) => (
	<Grid container spacing={3} sx={combineSx({ justifyContent: 'center' }, sx)}>
		{[...Array(size).keys()].map((el) => (
			<Grid item key={el} xs={12} sm={6} md={4}>
				<Skeleton variant='rectangular' width='100%' height={130} />
			</Grid>
		))}
	</Grid>
);

enum PostingAction {
	ACTIVATE = 'ACTIVATE',
	DEACTIVATE = 'DEACTIVATE',
	RENEW = 'RENEW',
}

const JobPostList = ({
	onCompletedQuery,
	onErrorQuery,
	searchTerm = '',
	tableProps,
	setFiltersPopupOpen,
	filtersPopupOpen = false,
	where,
	sx,
	orgId,
	selectable,
	tabValue,
}: JobPostListProps) => {
	const { apiFilters, filtersCount, appliedColumns } = useFilterContext();

	const { enqueueSnackbar } = useSnackbar();
	const theme = useTheme();
	const isXs = useMediaQuery(theme.breakpoints.down('sm'), { noSsr: true });
	const isSm = useMediaQuery(theme.breakpoints.between('sm', 'md'), {
		noSsr: true,
	});
	const isMd = useMediaQuery(theme.breakpoints.between('md', 'gridBreak'), {
		noSsr: true,
	});
	const isLtMd = useMediaQuery(theme.breakpoints.down('md'), { noSsr: true });
	const breakpoint = isXs ? 'xs' : isSm ? 'sm' : isMd ? 'md' : 'desktop';
	const isDesktopOrLaptop = useMediaQuery(theme.breakpoints.up('gridBreak'), {
		noSsr: true,
	});

	const redirect = useRedirect();
	const [page, setPage] = useState(0);
	const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE[breakpoint]);
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const [orderBy, setOrderBy] = useState<{ [k: string]: any }[]>([]);
	const [sortModel, setSortModel] = useState<GridSortModel>([]);
	const [bulkLoading, setBulkLoading] = useState(false);
	const [bulkAction, setBulkAction] = useState<PostingAction | null>(null);
	const [singleId, setSingleId] = useState<{
		id: string;
		action: PostingAction;
	} | null>(null);
	const [selectedIds, setSelectedIds] = useState<string[]>([]);

	// Reset the page whenever the underlying query changes
	useEffect(() => {
		setPage(0);
	}, [apiFilters, orderBy, setPage]);

	const [updateBulkJobPost, { loading: updateLoading }] = useMutation(
		JobPostList_BulkUpdateJobPostMDoc,
		{
			refetchQueries: [
				'JobPostList_SearchJobPosts',
				'BillingProvider_GetSubscription',
			],
			awaitRefetchQueries: true,
		},
	);

	const onPublish = async (jobPostIds: string[]) => {
		try {
			await updateBulkJobPost({
				variables: {
					jobPostingIds: jobPostIds,
					active: true,
					expirationDate: generateNewExpirationDate().toISOString(),
				},
			});
		} catch (e) {
			captureException(e, {
				extra: {
					jobPostId: jobPostIds,
				},
			});
			displayErrorMessageToast(e);
		}
	};

	const bulkPublish = async (jobPostIds: string[]) => {
		setBulkLoading(true);
		try {
			return await onPublish(jobPostIds);
		} catch (e) {
			captureException(e);
			console.error(e);
			enqueueSnackbar('An error occurred, please try again.', {
				variant: 'error',
			});
		} finally {
			setSingleId(null);
			setSelectedIds([]);
			setBulkAction(null);
			setBulkLoading(false);
		}
	};

	const onUnpublish = async (jobPostIds: string[]) => {
		try {
			await updateBulkJobPost({
				variables: {
					jobPostingIds: jobPostIds,
					active: false,
					expirationDate: null,
				},
			});
		} catch (e) {
			captureException(e, {
				extra: {
					jobPostId: jobPostIds,
				},
			});
			displayErrorMessageToast(e);
		}
	};

	const bulkUnpublish = async (jobPostIds: string[]) => {
		setBulkLoading(true);
		try {
			return await onUnpublish(jobPostIds);
		} catch (e) {
			captureException(e);
			console.error(e);
			enqueueSnackbar('An error occurred, please try again.', {
				variant: 'error',
			});
		} finally {
			setSingleId(null);
			setSelectedIds([]);
			setBulkAction(null);
			setBulkLoading(false);
		}
	};

	const onEdit = (jobPostId: string) => {
		redirect(
			buildInternalLink(RouteLink.routes.JOB_POSTING_EDIT, {
				postingId: jobPostId,
			}),
			{
				state: {
					backNav: {
						target: 'jobs',
					},
				},
			},
		);
	};

	// Reset page and orderBy upon search
	useEffect(() => {
		setPage(0);
		if (searchTerm) {
			// Default the search to sort by relevance.  The user can change the order afterwards.
			setOrderBy([
				{
					relevance: 'desc',
				},
			]);
			// Relevance does not appear in the DataGrid sort model
			setSortModel([]);
		} else {
			setOrderBy([]);
			setSortModel([]);
		}
	}, [searchTerm, setPage, setOrderBy]);

	// Don't render anything while switching from mobile to desktop until state fully reset
	// Can reproduce DataGrid crash by going to mobile, scrolling down until a couple pages have loaded, and then
	// switching to desktop. DataGrid will try to scroll to an index and fail.
	const [resetting, setResetting] = useState(false);

	useEffect(() => {
		setResetting(true);
	}, [isDesktopOrLaptop]);

	useEffect(() => {
		if (!resetting) {
			return;
		}
		setPage(0);
		setPageSize(DEFAULT_PAGE_SIZE[breakpoint]);
		setOrderBy(
			searchTerm
				? [
						{
							relevance: 'desc',
						},
				  ]
				: [],
		);
		setSortModel([]);
		setResetting(false);
	}, [
		resetting,
		breakpoint,
		searchTerm,
		setPage,
		setPageSize,
		setOrderBy,
		setSortModel,
		setResetting,
	]);

	const { data, loading, error, fetchMore, networkStatus } = useQuery(
		JobPostList_SearchJobPostsQDoc,
		{
			variables: {
				orgId: orgId,
				dateInPast: dateFromNow({ days: -30 }),
				search: searchTerm,
				filters: {
					_and: [...apiFilters, where || {}],
				},
				orderBy: [
					...orderBy,
					// Use the default ordering as a tiebreaker
					...DEFAULT_ORDER_BY,
				],
				limit: isDesktopOrLaptop ? pageSize : (page + 1) * pageSize,
				offset: isDesktopOrLaptop ? page * pageSize : 0,
				expirationDate: dateFromNow({
					days: JOB_POSTING_EXPIRING_SOON_WINDOW_DAYS,
				}).toISOString(),
			},
			notifyOnNetworkStatusChange: true,
			fetchPolicy: 'cache-and-network',
			nextFetchPolicy: 'cache-first',
		},
	);
	useNotifyError(error);

	useEffect(() => {
		const onCompleted = (_data?: JobPostList_SearchJobPostsQuery) => {
			if (onCompletedQuery) onCompletedQuery(_data);
		};
		const onError = (_error: ApolloError) => {
			if (onErrorQuery) onErrorQuery(_error);
		};
		if (onCompleted || onError) {
			if (onCompleted && !loading && !error) {
				onCompleted(data);
			} else if (onError && !loading && error) {
				onError(error);
			}
		}
	}, [loading, data, error, onCompletedQuery, onErrorQuery]);

	const jobPosts = useMemo(() => {
		return data?.relevantJobPosts.map((el) => el.jobPost!) || [];
	}, [data]);
	const totalJobPostSelectableCount = jobPosts.length;

	const handlePageChange = useCallback(
		async (newPage: number) => {
			await fetchMore({
				variables: {
					offset: newPage * pageSize,
				},
			});
			setPage(newPage);
		},
		[fetchMore, pageSize, setPage],
	);

	const handlePageSizeChange = useCallback(
		async (newPageSize: number) => {
			await fetchMore({
				variables: {
					limit: newPageSize,
					offset: page * newPageSize,
				},
			});
			setPageSize(newPageSize);
		},
		[fetchMore, page, setPageSize],
	);

	const handleFetchPage = useCallback(async () => {
		await fetchMore({
			variables: {
				limit: pageSize,
				offset: (page + 1) * pageSize,
			},
		});

		setPage(page + 1);
	}, [fetchMore, page, pageSize, setPage]);

	const handleSortChange = useCallback(
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		(newSortModel: GridSortModel, newOrderBy: { [k: string]: any }[]) => {
			setSortModel(newSortModel);
			setOrderBy(newOrderBy);
		},
		[setSortModel, setOrderBy],
	);

	const handleJobPostSelection = (ids: string[]) => {
		setSelectedIds(ids);
	};
	const handleSingleJobPostSelection =
		(action: PostingAction) => (id: string) => {
			setSingleId({ id, action });
		};

	const handleSelectAll = (jp) => {
		const postIds = jp.map((el) => el.id);
		setSelectedIds(postIds);
	};

	const handleBulkOperation = async () => {
		if (!bulkAction) {
			return;
		}
		const selectedArr = [...selectedIds];
		if (bulkAction === PostingAction.DEACTIVATE) {
			await bulkUnpublish(selectedArr);
		} else {
			// For both activation and renewal, we can use the publish operation to make sure they are
			// active and have a renewed expiration date
			await bulkPublish(selectedArr);
		}
	};

	const handleSingleOperation = async () => {
		if (!singleId) {
			return;
		}
		const selectedArr = [singleId.id];
		if (singleId.action === PostingAction.DEACTIVATE) {
			await bulkUnpublish(selectedArr);
		} else {
			// For both activation and renewal, we can use the publish operation to make sure they are
			// active and have a renewed expiration date
			await bulkPublish(selectedArr);
		}
	};
	const handleSingleCancel = () => {
		setSingleId(null);
	};

	const handleBulkClick = (_, action: string) =>
		setBulkAction(action as PostingAction);
	const handleBulkCancel = () => setBulkAction(null);

	const filtersActive = Object.values(filtersCount).some((el) => !!el);

	useEffect(() => {
		setSelectedIds([]);
		setBulkAction(null);
	}, [tabValue, jobPosts]);

	return (
		<React.Fragment>
			<Box sx={sx}>
				{resetting ? null : isDesktopOrLaptop ? (
					<>
						<Box>
							<Box>
								<Stack direction='row' justifyContent='space-between'>
									<FilterBar />
								</Stack>
							</Box>
						</Box>
						<Suspense
							fallback={<TableSkeleton sx={tableProps?.sx} size={pageSize} />}
						>
							<JobPostTable
								page={page}
								onChangeSort={handleSortChange}
								sortModel={sortModel}
								jobPosts={jobPosts}
								loading={loading || bulkLoading || updateLoading}
								pageSize={pageSize}
								onPageSizeChange={handlePageSizeChange}
								onPageChange={handlePageChange}
								rowCount={data?.agg.aggregate?.count || 0}
								filtering={filtersActive || !!searchTerm}
								columnVisibilityModel={appliedColumns}
								onEdit={
									tabValue &&
									[JobPostsTabs.ACTIVE, JobPostsTabs.EXPIRING_SOON].includes(
										tabValue,
									)
										? onEdit
										: undefined
								}
								onPublish={
									tabValue === JobPostsTabs.INACTIVE
										? handleSingleJobPostSelection(PostingAction.ACTIVATE)
										: undefined
								}
								onRenew={
									tabValue &&
									[JobPostsTabs.ACTIVE, JobPostsTabs.EXPIRING_SOON].includes(
										tabValue,
									)
										? handleSingleJobPostSelection(PostingAction.RENEW)
										: undefined
								}
								onUnpublish={
									tabValue &&
									[JobPostsTabs.ACTIVE, JobPostsTabs.EXPIRING_SOON].includes(
										tabValue,
									)
										? handleSingleJobPostSelection(PostingAction.DEACTIVATE)
										: undefined
								}
								checkboxSelection={selectable}
								onSelectionModelChange={handleJobPostSelection}
								selectionModel={Array.from(selectedIds)}
								{...tableProps}
							/>
						</Suspense>
					</>
				) : (
					<>
						{setFiltersPopupOpen ? (
							<FiltersPopup
								open={filtersPopupOpen}
								onClose={() => setFiltersPopupOpen(false)}
							/>
						) : undefined}
						<Suspense fallback={<GridSkeleton size={pageSize} />}>
							<JobPostGrid
								jobPosts={jobPosts}
								networkStatus={networkStatus}
								loading={loading || bulkLoading || updateLoading}
								filtersActive={filtersActive}
								onFetchPage={handleFetchPage}
								searchTerm={searchTerm}
								pageSize={pageSize}
								onCardClick={onEdit}
								count={data?.agg.aggregate?.count || 0}
								atMaxPage={
									!!data &&
									data.agg.aggregate!.count === data.relevantJobPosts.length
								}
								onEdit={
									tabValue &&
									[JobPostsTabs.ACTIVE, JobPostsTabs.EXPIRING_SOON].includes(
										tabValue,
									)
										? onEdit
										: undefined
								}
								onPublish={
									tabValue === JobPostsTabs.INACTIVE
										? handleSingleJobPostSelection(PostingAction.ACTIVATE)
										: undefined
								}
								onRenew={
									tabValue &&
									[JobPostsTabs.ACTIVE, JobPostsTabs.EXPIRING_SOON].includes(
										tabValue,
									)
										? handleSingleJobPostSelection(PostingAction.RENEW)
										: undefined
								}
								onUnpublish={
									tabValue &&
									[JobPostsTabs.ACTIVE, JobPostsTabs.EXPIRING_SOON].includes(
										tabValue,
									)
										? handleSingleJobPostSelection(PostingAction.DEACTIVATE)
										: undefined
								}
								onSelection={handleJobPostSelection}
								selected={Array.from(selectedIds)}
								selectable={selectable}
							/>
						</Suspense>
					</>
				)}
			</Box>
			{!!bulkAction && !!selectedIds.length && (
				<ConfirmationDialog
					open
					text={
						bulkAction === PostingAction.RENEW ? (
							<>
								Would you like to renew{' '}
								{selectedIds.length > 1
									? 'these job postings'
									: 'this job posting'}
								? The new expiration date will be{' '}
								<b>{formatDate(generateNewExpirationDate(), 'MMM d, yyyy')}</b>.
							</>
						) : (
							`Are you sure you want to ${
								bulkAction === PostingAction.DEACTIVATE
									? 'deactivate'
									: 'activate'
							} ${
								selectedIds.length > 1
									? 'these job postings'
									: 'this job posting'
							}?`
						)
					}
					onSubmit={handleBulkOperation}
					onClose={handleBulkCancel}
				/>
			)}
			{!!singleId && (
				<ConfirmationDialog
					open
					text={
						singleId.action === PostingAction.RENEW ? (
							<>
								Would you like to renew this job posting? The new expiration
								date will be{' '}
								<b>{formatDate(generateNewExpirationDate(), 'MMM d, yyyy')}</b>.
							</>
						) : (
							`Are you sure you want to ${
								singleId.action === PostingAction.DEACTIVATE
									? 'deactivate'
									: 'activate'
							} this job posting?`
						)
					}
					onSubmit={handleSingleOperation}
					onClose={handleSingleCancel}
				/>
			)}
			{selectable ? (
				<SelectionFloater
					minimized={isLtMd}
					selected={selectedIds}
					onClickSelectAll={
						totalJobPostSelectableCount !== selectedIds.length
							? () => handleSelectAll(jobPosts)
							: undefined
					}
					onClickReset={
						totalJobPostSelectableCount === selectedIds.length
							? () => setSelectedIds([])
							: undefined
					}
					onClick={handleBulkClick}
					options={
						tabValue === JobPostsTabs.ACTIVE
							? [
									{
										title: 'Deactivate',
										value: PostingAction.DEACTIVATE,
									},
									{
										title: 'Renew',
										value: PostingAction.RENEW,
									},
							  ]
							: tabValue === JobPostsTabs.EXPIRING_SOON
							? [
									{
										title: 'Renew',
										value: PostingAction.RENEW,
									},
									{
										title: 'Deactivate',
										value: PostingAction.DEACTIVATE,
									},
							  ]
							: [
									{
										title: 'Activate',
										value: PostingAction.ACTIVATE,
									},
							  ]
					}
					sx={{
						position: 'fixed',
						bottom: 24,
						left: '50%',
						transform: 'translateX(-50%)',
						zIndex: isLtMd ? theme.zIndex.drawer - 1 : 1,
					}}
				/>
			) : null}
		</React.Fragment>
	);
};

export default JobPostList;
