import React, { useState } from 'react';

import { InfoOutlined } from '@mui/icons-material';
import { Box, Divider, Stack, Typography, Link } from '@mui/material';
import * as yup from 'yup';

import RouteLink from '@ivy/components/atoms/RouteLink';
import { StripeIcon } from '@ivy/components/icons';
import FieldLabel from '@ivy/components/molecules/FieldLabel';
import BillingDisplay from '@ivy/components/organisms/BillingDisplay';
import CurrentPlan from '@ivy/components/organisms/CurrentPlan';
import PaymentDisplay from '@ivy/components/organisms/PaymentDisplay';
import { RouteUri } from '@ivy/constants/routes';
import { type FormStepProps } from '@ivy/lib/forms/formFormatHelpers';
import { CheckoutModes, SubscriptionStatus } from '@ivy/lib/services/billing';

import { type CheckoutFormValues, type ExtraParams } from '../CheckoutForm';

interface PaymentStepValues {
	newPaymentEntry: boolean;
	payment_id: string;
}
const validation = yup.object({
	newPaymentEntry: yup.boolean().required(),
	payment_id: yup.string().when('newPaymentEntry', {
		is: (val: boolean) => !val,
		then: (schema) => schema.required(),
		otherwise: (schema) => schema.nullable(),
	}),
});

const initialValues = {
	newPaymentEntry: false,
	payment_id: '',
};

const PaymentStep = ({
	formik,
	extra,
}: FormStepProps<CheckoutFormValues, ExtraParams>) => {
	const { values, setFieldValue } = formik;
	const [editMode, setEditMode] = useState(!values.payment_id);

	const handleChangePaymentMode = (val: boolean) => {
		setEditMode(val);
		setFieldValue('newPaymentEntry', val);
	};

	return (
		<>
			<Stack
				sx={{
					bgcolor: 'primary.translucent',
					color: 'text.icon',
					px: 2,
					py: 1,
					borderRadius: (theme) => `${theme.shape.borderRadius}px`,
					mb: 4,
					display:
						extra.mode === CheckoutModes.PAYMENT &&
						[
							SubscriptionStatus.INCOMPLETE,
							SubscriptionStatus.PAST_DUE,
							SubscriptionStatus.UNPAID,
						].includes(extra.currSubscription?.status as SubscriptionStatus) &&
						extra.currSubscription?.invoice?.status === 'open'
							? 'flex'
							: 'none',
				}}
				direction='row'
			>
				<InfoOutlined
					fontSize='small'
					sx={{
						color: 'text.icon',
						verticalAlign: 'middle',
						mr: 1,
					}}
				/>
				<Typography variant='body2'>
					Your account currently has an outstanding invoice that needs to be
					paid. Please complete the payment to maintain your subscription. To
					terminate your subscription,{' '}
					<Link
						component={RouteLink}
						underline='hover'
						fontWeight='bold'
						to={RouteLink.routes.MANAGEMENT_SUBSCRIPTION + '/plan'}
						sx={(theme) => ({
							cursor: 'pointer',
							color: theme.palette.secondary.main,
						})}
					>
						go to subscription settings
					</Link>
					.
				</Typography>
			</Stack>
			<Stack spacing={4} sx={{ width: { xs: 'auto', sm: '550px' } }}>
				{extra.currSubscription || extra.legacyJobPostingCount ? (
					<Box>
						<CurrentPlan
							slots={extra.currSubscription?.slots}
							interval={extra.currSubscription?.interval}
							subtotal={extra.currSubscription?.invoice?.total}
							status={extra.currSubscription?.status}
							legacyCount={
								!extra.currSubscription
									? extra.legacyJobPostingCount
									: undefined
							}
							hideTotal={extra.mode === CheckoutModes.PAYMENT}
						/>
						<Divider sx={{ mt: 3 }} />
					</Box>
				) : undefined}
				<BillingDisplay
					defaultValues={{
						name: values.name,
						email: values.email,
						streetAddress1: values.streetAddress1,
						streetAddress2: values.streetAddress2,
						city: values.city,
						state: values.state,
						zipcode: values.zipcode,
					}}
				/>
				<Box>
					<>
						<Box
							sx={{
								display: 'flex',
								flexDirection: 'row',
								alignItems: 'center',
								mb: 3,
							}}
						>
							<FieldLabel primary='Payment details' mr={1.5} />
							<StripeIcon sx={{ fontSize: '55px' }} />
						</Box>
						<Stack spacing={3}>
							<PaymentDisplay
								fixedEntry
								data={extra.paymentMethods}
								editMode={editMode}
								defaultPaymentId={values.payment_id}
								onEdit={(val) => handleChangePaymentMode(val)}
								onChange={(val) => setFieldValue('payment_id', val)}
								onCancel={() => handleChangePaymentMode(false)}
							/>
							<Box
								sx={{
									display: values.payment_id ? 'flex' : 'none',
								}}
							>
								<Link
									component={RouteLink}
									variant='caption'
									fontWeight='bold'
									underline='hover'
									to={RouteLink.routes.MANAGEMENT_SUBSCRIPTION + '/payment'}
									sx={(theme) => ({
										alignItems: 'center',
										mr: 'auto',
										cursor: 'pointer',
										color: theme.palette.secondary.main,
									})}
								>
									Add new payment method
								</Link>
							</Box>
						</Stack>
					</>
				</Box>
				<Stack
					display='flex'
					direction='row'
					alignItems='flex-start'
					sx={{ mt: 5 }}
				>
					<Typography>
						By clicking submit, you accept our{' '}
						<Typography
							component={RouteLink}
							to={RouteUri.TOS}
							variant='inherit'
							fontWeight='bold'
							color='primary.main'
							openInNewTab
						>
							Terms and Conditions
						</Typography>{' '}
						and authorize us to securely store your payment information for
						future subscription billing. You can manage or cancel your
						subscription at any time in your account settings.
					</Typography>
				</Stack>
			</Stack>
		</>
	);
};

export default PaymentStep;
export { type PaymentStepValues, validation, initialValues };
