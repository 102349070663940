import { loadStripe } from '@stripe/stripe-js';

import config from '@ivy/config';
import { type CheckoutFlow_InfoQuery } from '@ivy/gql/types/graphql';

export const getStripePromise = () => {
	const stripeOptions = config.stripeAccountApiKey
		? { stripeAccount: config.stripeAccountApiKey }
		: {};
	const stripePromise = config.stripePublishableKey
		? loadStripe(config.stripePublishableKey, stripeOptions)
		: null;
	return stripePromise;
};

export const LEON_CALENDAR_LINK = 'https://calendly.com/leon-adelman/zoom-chat';

export enum JobPostPriceInterval {
	MONTHLY = 'MONTHLY',
	YEARLY = 'YEARLY',
}

export enum SubscriptionTiers {
	STARTER = 'STARTER',
	PROFESSIONAL = 'PROFESSIONAL',
	ENTERPRISE = 'ENTERPRISE',
}

export enum SubscriptionStatus {
	ACTIVE = 'active',
	PAUSED = 'paused',
	PAST_DUE = 'past_due',
	INCOMPLETE = 'incomplete',
	EXPIRING = 'expiring',
	PROCESSING = 'processing',
	REQUIRES_VERIFICATION = 'requires_verification',
	UNPAID = 'unpaid',
}

export enum CheckoutModes {
	PROCESS = 'process',
	PAYMENT = 'payment',
}

export const JOBPOSTINTERVAL2STEMLESS: Readonly<{
	[k in JobPostPriceInterval]: string;
}> = Object.freeze({
	[JobPostPriceInterval.MONTHLY]: 'Month',
	[JobPostPriceInterval.YEARLY]: 'Year',
});

export const getJobPostValRep = (slots: number) => {
	let jobPostValRep = slots;

	switch (jobPostValRep) {
		case 11:
			jobPostValRep = 15;
			break;
		case 12:
			jobPostValRep = 20;
			break;
		case 13:
			jobPostValRep = 30;
			break;
		case 14:
			jobPostValRep = 40;
			break;
		case 15:
			jobPostValRep = 50;
			break;
		case 16:
			jobPostValRep =
				parseInt(billingSliderMarks[billingSliderMarks.length - 2].label) + 1;
			break;
		default:
			break;
	}

	return jobPostValRep;
};

export const getSliderValue = (slots: number) => {
	let slideVal = slots;

	switch (slideVal) {
		case 15:
			slideVal = 11;
			break;
		case 20:
			slideVal = 12;
			break;
		case 30:
			slideVal = 13;
			break;
		case 40:
			slideVal = 14;
			break;
		case 50:
			slideVal = 15;
			break;
		default:
			break;
	}

	return slideVal;
};

export const resolveJobPostingPrice = (
	data: CheckoutFlow_InfoQuery['jobPostPrices'],
	interval: keyof typeof JobPostPriceInterval,
): CheckoutFlow_InfoQuery['jobPostPrices'][0] | null => {
	const parsedData = data;
	for (let i = 0; i < parsedData.length; i++) {
		const price = parsedData[i];
		if (price.interval === interval) {
			return price;
		}
	}
	return null;
};

export const resolveBasePrice = (
	data: CheckoutFlow_InfoQuery['jobPostPrices'],
): CheckoutFlow_InfoQuery['jobPostPrices'][0]['tiers'][0] | null => {
	const priceInterval = resolveJobPostingPrice(
		data,
		JobPostPriceInterval.MONTHLY,
	);
	if (!priceInterval) return null;

	const price = priceInterval.tiers.find((tier) => tier?.limit === 1);
	if (price) return price;
	return null;
};

export const calculateDiscountedPrice = (
	prices: CheckoutFlow_InfoQuery['jobPostPrices'][0],
	slots: number,
	interval: JobPostPriceInterval,
) => {
	let finalTier: CheckoutFlow_InfoQuery['jobPostPrices'][0]['tiers'][0] | null =
		null;
	const jobPostValRep = slots;
	if (!prices || !jobPostValRep) return 0;

	for (let i = 0; i < prices.tiers.length; i++) {
		const tier = prices.tiers[i];
		if (tier.limit !== null && jobPostValRep <= tier.limit) {
			return interval === JobPostPriceInterval.MONTHLY
				? tier.amount
				: tier.amount / jobPostValRep / 12;
		} else if (tier.limit === null) {
			finalTier = tier;
		}
	}
	if (finalTier) {
		return finalTier.amount;
	}

	return 0;
};

export const calculateDiscountAmount = (
	prices: CheckoutFlow_InfoQuery['jobPostPrices'][0],
	slots: number,
	interval: JobPostPriceInterval,
) => {
	let finalTier: CheckoutFlow_InfoQuery['jobPostPrices'][0]['tiers'][0] | null =
		null;
	const jobPostValRep = slots;
	if (!prices || !jobPostValRep) return 0;

	for (let i = 0; i < prices.tiers.length; i++) {
		const tier = prices.tiers[i];
		if (tier.limit !== null && jobPostValRep <= tier.limit) {
			return interval === JobPostPriceInterval.MONTHLY
				? jobPostValRep * tier.amount
				: tier.amount;
		} else if (tier.limit === null) {
			finalTier = tier;
		}
	}
	if (finalTier) {
		return jobPostValRep * finalTier.amount;
	}

	return 0;
};

export const calculateRegularAmount = (
	prices: CheckoutFlow_InfoQuery['jobPostPrices'][0],
	basePrice: CheckoutFlow_InfoQuery['jobPostPrices'][0]['tiers'][0],
	slots: number,
	interval: JobPostPriceInterval,
) => {
	let finalTier: CheckoutFlow_InfoQuery['jobPostPrices'][0]['tiers'][0] | null =
		null;
	const jobPostValRep = slots;
	if (!prices || !basePrice || !jobPostValRep) return 0;

	for (let i = 0; i < prices.tiers.length; i++) {
		const tier = prices.tiers[i];
		if (tier.limit !== null && jobPostValRep <= tier.limit) {
			return interval === JobPostPriceInterval.MONTHLY
				? jobPostValRep * basePrice.amount
				: basePrice.amount * 12 * jobPostValRep;
		} else if (tier.limit === null) {
			finalTier = tier;
		}
	}

	if (finalTier) {
		return interval === JobPostPriceInterval.MONTHLY
			? jobPostValRep * basePrice.amount
			: basePrice.amount * 12 * jobPostValRep;
	}

	return 0;
};

export const calculateDiscountRate = (
	price: CheckoutFlow_InfoQuery['jobPostPrices'][0],
	basePrice: CheckoutFlow_InfoQuery['jobPostPrices'][0]['tiers'][0],
	slots: number,
	interval: JobPostPriceInterval,
) => {
	const regularAmount = calculateRegularAmount(
		price,
		basePrice,
		slots,
		interval,
	);
	const discountAmount = calculateDiscountAmount(price, slots, interval);
	const percentageDifference =
		((regularAmount - discountAmount) / regularAmount) * 100;
	return percentageDifference.toFixed(1);
};

export const resolveSubscriptionStatus = (
	subscriptionStatus: string,
	paymentStatus?: string | null,
	cancelAt?: number | null,
) => {
	let currStatus = subscriptionStatus;

	if (subscriptionStatus === SubscriptionStatus.ACTIVE && cancelAt) {
		currStatus = SubscriptionStatus.EXPIRING;
	} else if (
		subscriptionStatus === SubscriptionStatus.INCOMPLETE &&
		paymentStatus === 'requires_action'
	) {
		currStatus = SubscriptionStatus.REQUIRES_VERIFICATION;
	} else if (
		(subscriptionStatus === SubscriptionStatus.ACTIVE ||
			subscriptionStatus === SubscriptionStatus.PAST_DUE) &&
		paymentStatus === 'processing'
	) {
		currStatus = SubscriptionStatus.PROCESSING;
	}

	return currStatus;
};

export const billingSliderMarks = [
	{
		value: 1,
		label: '1',
	},
	{
		value: 2,
		label: '2',
	},
	{
		value: 3,
		label: '3',
	},
	{
		value: 4,
		label: '4',
	},
	{
		value: 5,
		label: '5',
	},
	{
		value: 6,
		label: '6',
	},
	{
		value: 7,
		label: '7',
	},
	{
		value: 8,
		label: '8',
	},
	{
		value: 9,
		label: '9',
	},
	{
		value: 10,
		label: '10',
	},
	{
		value: 11,
		label: '15',
	},
	{
		value: 12,
		label: '20',
	},
	{
		value: 13,
		label: '30',
	},
	{
		value: 14,
		label: '40',
	},
	{
		value: 15,
		label: '50',
	},
	{
		value: 16,
		label: '51+',
	},
];
