import React from 'react';

import {
	Stack,
	Typography,
	type SxProps,
	type Theme,
	Skeleton,
} from '@mui/material';

import { JobPostPriceInterval } from '@ivy/lib/services/billing';

interface CurrentPlanProps {
	slots?: number;
	interval?: string;
	status?: string;
	subtotal?: number;
	loading?: boolean;
	sx?: SxProps<Theme>;
	legacyCount?: number;
	hideTotal?: boolean;
}

const CurrentPlan = ({
	slots,
	interval,
	loading,
	sx,
	legacyCount,
}: CurrentPlanProps) => {
	if (legacyCount) {
		return (
			<Stack direction='column' spacing={2} sx={sx}>
				<Typography variant='body1' fontWeight='bold'>
					Your current account
				</Typography>
				<Stack direction='row' spacing={2}>
					<Typography variant='body2' sx={{ width: '160px' }}>
						Active job postings
					</Typography>
					<Typography variant='body2' fontWeight='medium'>
						{legacyCount}
					</Typography>
				</Stack>
			</Stack>
		);
	}

	const intervalPlainText = interval
		? interval === JobPostPriceInterval.MONTHLY
			? 'monthly'
			: 'yearly'
		: '-';

	if (loading) {
		return (
			<Stack direction='column' spacing={2} sx={sx}>
				<Skeleton
					variant='rectangular'
					sx={{
						borderRadius: (theme) => `${theme.shape.borderRadius}px`,
					}}
					width='140px'
					height='20px'
				/>
				<Skeleton
					variant='rectangular'
					sx={{
						borderRadius: (theme) => `${theme.shape.borderRadius}px`,
					}}
					width='300px'
					height='20px'
				/>
				<Skeleton
					variant='rectangular'
					sx={{
						borderRadius: (theme) => `${theme.shape.borderRadius}px`,
					}}
					width='300px'
					height='20px'
				/>
			</Stack>
		);
	}

	return (
		<Stack direction='column' spacing={2} sx={sx}>
			<Typography variant='body1' fontWeight='bold'>
				Your current plan
			</Typography>
			<Stack direction='row' spacing={2}>
				<Typography variant='body2' sx={{ width: '120px' }}>
					Job posting slots
				</Typography>
				<Typography variant='body2' fontWeight='medium'>
					{slots || 0} {intervalPlainText}
				</Typography>
			</Stack>
		</Stack>
	);
};

export default CurrentPlan;
