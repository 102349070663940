import React from 'react';

import { useSubscription } from '@apollo/client';
import {
	ArticleOutlined,
	Search,
	PersonSearchOutlined,
} from '@mui/icons-material';
import { matchRoutes, type Location } from 'react-router-dom';

import RouteLink from '@ivy/components/atoms/RouteLink';
import { MoneyIcon, ReviewsIcon } from '@ivy/components/icons';
import {
	type CrispContextValue,
	useCrisp,
} from '@ivy/components/providers/CrispProvider';
import { Role } from '@ivy/constants/account';
import { SiteMapCategories } from '@ivy/constants/sitemap';
import { useCurrentAccount } from '@ivy/gql/hooks';
import { type CurrAcc } from '@ivy/gql/reactives';
import { gql } from '@ivy/gql/types';
import { logOut } from '@ivy/lib/storage/token';
import { buildInternalLink, type WhitelabelTo } from '@ivy/lib/util/route';
import useWhitelabel from '@ivy/lib/whitelabel/useWhitelabel';
import { JobPostsTabs } from '@ivy/views/job/JobPosting/JobPosting';

const OrgMenu_UnreadItemsSDoc = gql(/* GraphQL */ `
	subscription OrgMenu_UnreadItems($accountId: uuid!) {
		orgUser: org_user_by_pk(id: $accountId) {
			id
			numUnseenCandidates: num_unseen_candidates
		}
	}
`);

export interface MenuNodeLeaf {
	label: string;
	shortLabel?: string;
	route?: WhitelabelTo;
	openInNewTab?: boolean;
	additionalMatches?: string[];
	onClick?: React.MouseEventHandler;
	badge?: number;
	secondary?: string;
}

export interface MenuNodeParent {
	icon: React.ReactNode;
	label: string;
	submenu: MenuNodeLeaf[];
}

export type MenuNode = MenuNodeLeaf | MenuNodeParent;

export const isMenuItemSelected = (
	item: MenuNode,
	location: Location,
): boolean => {
	if ('submenu' in item) {
		return item.submenu.some((el) => isMenuItemSelected(el, location));
	}
	return (
		item.route === location.pathname.toLowerCase() ||
		!!item.additionalMatches?.includes(location.pathname.toLowerCase())
	);
};

export const getHomePageMenuItems = (currAcc: CurrAcc | null): MenuNode[] => {
	return [
		{
			label: 'Job Search',
			route: RouteLink.routes.ROOT,
			icon: <Search />,
		},
		!currAcc || currAcc.isClinician || currAcc.superuser || currAcc.accessSalary
			? {
					label: 'Salaries',
					route: RouteLink.routes.SALARY,
					icon: (
						<MoneyIcon
							sx={{
								color: 'primary.main',
							}}
						/>
					),
			  }
			: undefined,
		{
			label: 'Reviews',
			route: RouteLink.routes.REVIEW,
			icon: (
				<ReviewsIcon
					sx={{
						color: 'primary.main',
					}}
				/>
			),
		},
		{
			label: 'For Employers',
			route: RouteLink.routes.FOR_EMPLOYERS,
			icon: <PersonSearchOutlined />,
		},
		{
			label: 'Resources',
			icon: <ArticleOutlined />,
			submenu: [
				{
					label: 'About Us',
					route: RouteLink.routes.ABOUT_US,
				},
				{
					label: 'FAQs',
					route: RouteLink.routes.FAQ_ALL,
				},
				{
					label: 'Blog',
					route: RouteLink.routes.BLOG_LIST,
				},
				{
					label: '2023 State of the EM Employer Market',
					// TODO: update this to 2024
					shortLabel: '2024 Employer Report',
					route:
						'https://emworkforce.substack.com/p/state-of-the-us-emergency-medicine-677',
				},
				{
					label: 'EM Workforce Newsletter',
					shortLabel: 'Physician Newsletter',
					route: 'https://emworkforce.substack.com',
					openInNewTab: true,
				},
			],
		},
	].filter((el) => !!el) as MenuNode[];
};

export const getFooterMenuItems = (
	{ crisp }: { crisp: CrispContextValue },
	currAcc: CurrAcc | null,
): MenuNodeLeaf[] => [
	...getHomePageMenuItems(currAcc)
		.map((item) => ('submenu' in item ? item.submenu : item))
		.flat(),
	{
		label: 'FAQ',
		route: RouteLink.routes.FAQ_LIST,
	},
	{
		label: 'Support',
		onClick: crisp.handleOpenLauncher,
	},
	{
		label: 'Privacy Policy',
		route: RouteLink.routes.PRIVACY_POLICY,
	},
	{
		label: 'Terms of Service',
		route: RouteLink.routes.TOS,
	},
	{
		label: 'Sitemap',
		route: buildInternalLink(RouteLink.routes.SITEMAP_INDEX, {
			category: SiteMapCategories.STATE,
		}),
	},
];

const getAdditionalMenuItems = ({ account }: { account: CurrAcc | null }) => {
	const addit: (MenuNodeLeaf | null)[] = [];
	if (account?.superuser) {
		addit.push(
			{
				label: 'EMRA Admin',
				route: RouteLink.routes.EMRA_ADMIN,
			},
			{
				label: 'ACEP Admin',
				route: RouteLink.routes.ACEP_ADMIN,
			},
			{
				label: 'Admin',
				route: RouteLink.routes.ADMIN,
			},
		);
	} else {
		account?.roles.forEach(({ role }) => {
			switch (role) {
				case Role.ACEP_ADMIN:
					addit.push({
						label: 'ACEP Admin',
						route: RouteLink.routes.ACEP_ADMIN,
					});
					break;
				case Role.EMRA_ADMIN:
					addit.push({
						label: 'EMRA Admin',
						route: RouteLink.routes.EMRA_ADMIN,
					});
					break;
			}
		});
	}
	return addit;
};

const getCommonMenuItems = ({ crisp }) => [
	{
		label: 'My Account',
		route: RouteLink.routes.SETTING,
	},
	{
		label: 'Favorites',
		route: RouteLink.routes.FAVORITES,
	},
	{
		label: 'Support',
		onClick: crisp.handleOpenLauncher,
	},
	{
		label: 'Log Out',
		onClick: logOut,
	},
];

export const getClinicianMenuItems = (): (MenuNode | null)[] => [
	{
		label: 'Profile & CV',
		route: RouteLink.routes.EDIT_CLINICIAN,
	},
	{
		label: 'Applications',
		route: RouteLink.routes.JOB_APPLICATION_LIST,
	},
];

export const getOrgMenuItems = ({
	numUnseenCandidates,
}: {
	numUnseenCandidates: number;
}): (MenuNode | null)[] => [
	{
		label: 'Candidates',
		route: RouteLink.routes.CANDIDATE_LIST,
		badge: numUnseenCandidates,
		secondary: numUnseenCandidates ? `${numUnseenCandidates} New` : undefined,
	},
	{
		label: 'Facilities',
		route: RouteLink.routes.MANAGEMENT_FACILITY,
	},
	{
		label: 'Job Postings',
		route: buildInternalLink(RouteLink.routes.JOB_POSTING_LIST_TAB, {
			tab: JobPostsTabs.ACTIVE,
		}),
	},
	{
		label: 'Colleagues',
		route: RouteLink.routes.MANAGEMENT_ACCOUNT,
	},
	{
		label: 'Employer Profile',
		route: buildInternalLink(RouteLink.routes.ORG_SHOW, {
			orgId: 'current',
		}),
	},
	{
		label: 'Company Settings',
		route: RouteLink.routes.MANAGEMENT_SETTING,
	},
	{
		label: 'Subscription Settings',
		route: RouteLink.routes.MANAGEMENT_SUBSCRIPTION,
	},
];

export const useMenuProps = () => {
	const wl = useWhitelabel();
	const currAcc = useCurrentAccount();
	const crisp = useCrisp();
	// TODO: report errors to sentry, but ignore JWT broken connections
	const { data } = useSubscription(OrgMenu_UnreadItemsSDoc, {
		variables: {
			accountId: currAcc?.id,
		},
		// This won't establish a websocket connection if skip is true
		skip: !currAcc?.isOrgUser,
	});

	let menuItems: (MenuNodeLeaf | null)[] = [];
	if (currAcc?.isClinician) {
		menuItems.push(...getClinicianMenuItems());
	}
	if (currAcc?.isOrgUser) {
		menuItems.push(
			...getOrgMenuItems({
				numUnseenCandidates: data?.orgUser?.numUnseenCandidates || 0,
			}),
		);
	}
	const addit = getAdditionalMenuItems({ account: currAcc });
	if (addit.length) {
		menuItems.push(null, ...addit);
	}
	if (currAcc) {
		const common = getCommonMenuItems({ crisp });
		if (common.length) {
			menuItems.push(null, ...common);
		}
	}
	// Filter out any that don't have an enabled route
	menuItems = menuItems.filter(
		(el) =>
			!el ||
			!el.route ||
			matchRoutes(
				wl.enabledRoutes.map((sel) => ({ path: sel.path })),
				el.route,
			),
	);
	// Trim nulls from the beginning and end (these correspond to dividers in the UI)
	while (menuItems[0] === null) {
		menuItems.shift();
	}
	while (menuItems[menuItems.length - 1] === null) {
		menuItems.pop();
	}
	const numTotal = !menuItems.length
		? 0
		: menuItems.reduce((total, item) => {
				if (item && 'badge' in item && item.badge) {
					return total + 1;
				}
				return total;
		  }, 0);
	return { menuItems, numTotal };
};
